import * as React from 'react';

import { SorterResult } from 'antd/lib/table';

import { SortingDirection } from '@common/react/components/Pages/ItemsPage';
import { TableRemoteSorterData } from '@common/react/smart components/Table/TableRemoteSorter';
import { WithId } from '@common/typescript/objects/WithId';

export function useTableRemoteSorter<T extends WithId>(): TableRemoteSorterData<T> {
	const [sorter, setSorter] = React.useState<SorterResult<T> | undefined>(undefined);

	return React.useMemo(() => ({
		sorter: {
			column: sorter?.column ? [{
				caption: sorter.field,
				direction: sorter.order === 'descend'
					? SortingDirection.Descending
					: SortingDirection.Ascending,
			}] : [],
		},
		setSorter,
	}), [sorter]);
}
