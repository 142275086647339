import * as React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import Table, { ExpandIconProps } from 'antd/lib/table';
import { ColumnProps } from 'antd/lib/table/interface';
import * as Yup from 'yup';

import TableDataProvider, { Pagination, TableData } from '@common/react/smart components/Table/TableDataProvider';
import { listWithModal, listWithModalHOCProps } from '@common/react/components/List/ListWithModal/ListWithModal';
import { useTableRemoteSorter } from '@common/react/hooks/RemoteData/useTableRemoteSorter';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { WithId } from '@common/typescript/objects/WithId';

import { ItemPageEditor } from '@app/components/Pages/PageWrappers/WithModalEditor';
import { ActionButtons } from '@app/components/UI/Buttons/ActionButtons';
import { FiltersComponent } from '@app/components/UI/Filters/FilterComponent';
import { useFilters } from '@app/components/UI/Filters/FilterHook';

import { NEW_PET_ID } from '@app/components/Pages/PetEditor/PetApi';
import { ActionFactory } from '@app/components/Utils/ActionFactory';
import { CompleteHandler, PartialId } from '@app/store/SelectList/UtilityTypes';
import { TotalQuantity } from '@app/components/UI/Filters/TotalQuantity';

interface ItemsPageProps<T extends WithId> {
	getListEndpoint: string;
	requestAdditionalParams?: Record<string, unknown>;
	createEndpoint: string;
	updateEndpoint: string;
	getEndpoint?: string;
	requestParams?: Record<string, unknown>;

	onCompleted?: CompleteHandler<T>;
	width?: string | number; // modal width

	itemName: string;
	columns: Array<ColumnProps<T>>;
	getChanges?: (origin: T, updated: T) => PartialId<T>;
	formatCreatingData?: (updated: T) => PartialId<T>;
	onLoaded?: (data: TableData<T>) => void;
	onBlur?: () => void;

	actions?: ActionFactory<T>;
	withTotalQuantity?: boolean;

	validationSchema: Yup.ObjectSchema<Record<string, unknown>>;
	getInitialValues: (item: T) => T;
	children?: React.ReactNode;
	tableClassName?: string;
	filterNames?: Array<string>;
	sorterNames?: Array<string>;
	expandedRow?: {
		render: (record: T, index: number, indent: number, expanded: boolean) => React.ReactNode;
		expandable: (record: T) => React.ReactNode;
	};
	header?: (data: TableData<T>, dialog: () => void) => React.ReactNode;
}

type Props<T extends WithId> = ItemsPageProps<T> & listWithModalHOCProps;

export function getActionsColumn<T extends WithId>(
	t: TFunction,
	openDialog: (id: number) => void,
	reload: () => void,
	pagination: Pagination,
	actions?: ActionFactory<T>,
): Array<ColumnProps<T>> {
	if (actions === undefined) return [];

	return TableBuilder.shape<T>()
		.addColumn({
			title: t('table.columns.actions'),
			dataIndex: 'actions',
			width: '100px',
			align: 'center',
			render: (text, record: T) => (
				<ActionButtons
					actions={actions({
						openDialog: () => openDialog(record.id), reload, record, pagination,
					})}
				/>
			),
		}).build();
}

const ItemsPageWrapInner = <T extends WithId>(props: Props<T>): React.ReactNode => {
	const {
		openDialog,
		closeDialog,
		visible,
		modalItemId,
		columns,
		getListEndpoint,
		children,
		validationSchema,
		createEndpoint,
		updateEndpoint,
		getEndpoint,
		requestParams = {},
		getInitialValues,
		itemName,
		requestAdditionalParams = {},
		tableClassName = '',
		filterNames = [],
		sorterNames = props.filterNames,
		withTotalQuantity = true,

		expandedRow,

		header,

		actions,
		getChanges,
		formatCreatingData,

		onCompleted,
		width,
		onLoaded,
	} = props;
	const { t } = useTranslation();
	const sorter = useTableRemoteSorter<T>();

	const [filters, onChange, setQueryParams, queryParams, clearQueryParams, onDeselect] = useFilters();
	const onlyFilters = requestAdditionalParams?.filters;

	const reqParams = React.useMemo(() => ({
		...requestAdditionalParams,
		...sorter.sorter,
		filters: {
			...queryParams,
			...onlyFilters as object,
		},

	}), [queryParams, sorter]);

	const tableClassNames = `table-mobile small-padding-table ${tableClassName}`;

	return (
		<>
			<TableDataProvider
				endpoint={getListEndpoint}
				reloadOnParamsChanged
				requestParams={reqParams}
			>
				{(data: TableData<T>) => {
					if (data.items.list.length && !data.isLoading) onLoaded?.(data);

					return (
						<>
							{header?.(data, () => openDialog(NEW_PET_ID))}

							{filterNames.length > 0 && (
								<FiltersComponent
									filters={filters}
									onChange={onChange}
									setQueryParams={setQueryParams}
									clearQueryParams={clearQueryParams}
									onDeselect={onDeselect}
									filterNames={filterNames}
									sorterNames={sorterNames}
									isLoading={data.isLoading}
									sorter={sorter}
								/>
							)}
							<TotalQuantity count={data.pagination.total} withTotalQuantity={withTotalQuantity} />
							<Table
								onRow={() => ({
									onMouseLeave: () => props.onBlur?.(), // mouse leave row
								})}
								columns={[
									...columns,
									...getActionsColumn<T>(
										t,
										openDialog,
										data.reload,
										data.pagination,
										actions,
									)]}
								dataSource={data.items.list}
								pagination={data.pagination}
								loading={data.isLoading}
								onChange={((pagination, filters, sorterResult) => {
									sorter.setSorter(sorterResult);
								})}
								childrenColumnName="child"
								rowKey="id"
								className={tableClassNames}
								expandIcon={
									(props: ExpandIconProps<T>) =>
										(expandedRow?.expandable(props.record)
											? (
												<div
													role="button"
													tabIndex={0}
													className={`ant-table-row-expand-icon ant-table-row-${props.expanded ? 'expanded' : 'collapsed'}`}
													aria-label="Expand row"
													onClick={(event: React.MouseEvent<HTMLDivElement>) => props.onExpand(props.record, event)}
												/>
											)
											: null)
								}
								expandedRowRender={expandedRow?.render}
							/>
							<ItemPageEditor<T>
								reload={data.reload}
								handleClose={closeDialog}
								visible={visible}
								createEndpoint={createEndpoint}
								getEndpoint={getEndpoint}
								requestParams={requestParams}
								itemName={itemName}
								initialValues={getInitialValues(data.items.list.find((x) => x.id === modalItemId))}
								getInitialValues={getInitialValues}
								updateEndpoint={updateEndpoint}
								validationSchema={validationSchema}
								getChanges={getChanges}
								formatCreatingData={formatCreatingData}
								modalItemId={modalItemId}
								onCompleted={onCompleted}
								width={width}
							>
								{children}
							</ItemPageEditor>
						</>
					);
				}}
			</TableDataProvider>
		</>
	);
};

export const ItemsPageWrap = listWithModal(ItemsPageWrapInner) as unknown as <T extends WithId>(props: ItemsPageProps<T>) =>
	React.ReactElement<Props<T>>;
