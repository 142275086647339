import React from 'react';

import { WithId } from '@common/typescript/objects/WithId';
import { Pagination } from '@common/react/smart components/Table/TableDataProvider';

import {
	DeleteRemote, DeleteWithRedux, EditButton, RequestButton, PrintButton,
} from '@app/components/UI/Buttons/ActionButtons';

export interface IActions {
	key: string;
	element: React.ReactElement | null;
}

export interface ActionFactoryParams<T extends WithId> {
	reload: () => void;
	pagination?: Pagination;
	record: T;
	openDialog?: () => void;
}
export type ActionFactory<T extends WithId> = (params: ActionFactoryParams<T>) => Array<IActions>;

export class ActionBuilder {
	private template: Array<IActions> = Array<IActions>();

	public build(): Array<IActions> {
		const result = this.template;
		this.template = Array<IActions>();

		return result;
	}

	public addEdit(openDialog?: () => void, linkPath?: string): ActionBuilder {
		this.template.push({
			key: 'edit',
			element: (
				<EditButton
					onClick={openDialog}
					linkPath={linkPath}
				/>
			),
		});

		return this;
	}

	public addDelete<T extends WithId>(
		endpoint: string,
		itemName: string,
		reload: () => void,
		record: T,
		pagination?: Pagination,
		requestParams?: Record <string, unknown>,
		errorSettings?: (error: string) => void,
	): ActionBuilder {
		this.template.push({
			key: 'delete',
			element: (
				<DeleteRemote
					requestParams={requestParams}
					itemName={itemName}
					reload={reload}
					id={record.id}
					deleteEndpoint={endpoint}
					pagination={pagination}
					errorSettings={errorSettings}
				/>
			),
		});

		return this;
	}

	public addPrint<T extends WithId>(record: T): ActionBuilder {
		this.template.push({
			key: 'print',
			element: (<PrintButton id={record.id} />),
		});

		return this;
	}

	public addDeleteWithRedux<T extends WithId>(
		record: T,
		deleteItem: (e?: React.MouseEvent<HTMLButtonElement>) => void,
	): ActionBuilder {
		this.template.push({
			key: 'deleteWithRedux',
			element: (
				<DeleteWithRedux record={record} deleteItem={deleteItem} />
			),
		});

		return this;
	}

	public addRequestBtn(
		endpoint: string,
		itemName: string,
		reload: () => void,
		disabled: boolean,
		requestParams?: Record <string, unknown>,
		message?: string,
		icon?: React.ReactNode,
	): ActionBuilder {
		this.template.push({
			key: 'request',
			element: (
				<RequestButton
					requestParams={requestParams}
					itemName={itemName}
					reload={reload}
					disabled={disabled}
					updateEndpoint={endpoint}
					message={message}
					icon={icon}
				/>
			),
		});

		return this;
	}

	public addBtn(
		button: React.ReactElement | null,
	): ActionBuilder {
		this.template.push({
			key: 'update',
			element: button,
		});

		return this;
	}
}
