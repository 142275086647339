import * as React from 'react';

interface OwnProps {
	children: JSX.Element;
	confirm: (() => void) | undefined;
	reset: () => void;
}

export const TableFilterContainer: React.FC<OwnProps> = (props: OwnProps) => {
	const { children, confirm, reset } = props;

	return (
		<div className="ant-dropdown-menu">
			<div className="form-group" style={{ paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px' }}>
				{children}
			</div>
			<div className="clearfix ant-table-filter-dropdown-btns">
				<div className="pull-left"><a onClick={confirm}>OK</a></div>
				<div className="pull-right"><a onClick={reset}>Reset</a></div>
			</div>
		</div>
	);
};
