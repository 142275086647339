import * as React from 'react';

import { ColumnProps } from 'antd/lib/table';

import { WithId } from '@common/typescript/objects/WithId';

import { TableFilterContainer } from '@app/components/Filters/TableFilterContainer';

export const textSearch = <T extends WithId>(caption: string): Partial<ColumnProps<T>> => ({
	filterDropdown: (props) => {
		const value = props.selectedKeys as string | Array<string> | number;
		const setValue = props.setSelectedKeys as unknown as (s: string | Array<string> | number) => void;
		const { confirm } = props;
		const clearFilters = () => {
			setValue('');
			if (confirm) {
				confirm();
			}
		};

		return (
			<TableFilterContainer confirm={confirm} reset={clearFilters}>
				<input
					className="form-control ant-dropdown-menu-item"
					placeholder={`Search ${caption}`}
					value={value}
					onChange={({ currentTarget }: React.ChangeEvent<HTMLInputElement>) =>
						setValue(currentTarget.value)}
					onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => (confirm ? event.key === 'Enter' && confirm() : null)}
					ref={(elem) => elem && elem.focus()}
				/>
			</TableFilterContainer>
		);
	},
});
