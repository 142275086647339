import React from 'react';
import { Link } from 'react-router-dom';

import { WithId } from '@common/typescript/objects/WithId';

import { DeactivateDropdownButton } from '@app/components/UI/Buttons/DeactivateDropdownButton';
import { EmailInputDropdownButton } from '@app/components/UI/Buttons/EmailInputDropdownButton';
import { TransmutationButton } from '@app/components/UI/Buttons/TransmutationButton';
import { User } from '@app/objects/User';

export interface ItemActionMenu {
	key: string;
	element: React.ReactElement | null;
}

export class ActionMenuBuilder {
	private template: Array<ItemActionMenu> = Array<ItemActionMenu>();

	public build(): Array<ItemActionMenu> {
		const result = this.template;
		this.template = Array<ItemActionMenu>();

		return result;
	}

	public addEdit(link: string, display: boolean = true): ActionMenuBuilder {
		if (display) {
			this.template.push({
				key: 'edit',
				element: (
					<Link
						className=""
						title="Edit"
						to={link}
					>
						<i className="fa fa-pencil" />
						<span className="icon-margin">Edit</span>
					</Link>
				),
			});
		}

		return this;
	}

	public addTransmutationButton(
		record: User,
		onSuccess?: () => void,
		display: boolean = true,
	): ActionMenuBuilder {
		if (display) {
			this.template.push({
				key: 'transmutation',
				element: (
					<TransmutationButton
						user={record}
						title="Login as"
						onSuccess={onSuccess}
					/>
				),
			});
		}

		return this;
	}

	public addEmailButton<T extends WithId & { email: string}>(
		record: T,
		display: boolean = true,
		allowResend: boolean = true,
		minified: boolean = true,
	): ActionMenuBuilder {
		if (display) {
			this.template.push({
				key: 'email',
				element: (<EmailInputDropdownButton userId={record.id} email={record.email} allowResend={allowResend} minified={minified} />),
			});
		}

		return this;
	}

	public addDeleteButton(id: number, onDelete: () => void, display: boolean = true): ActionMenuBuilder {
		if (display) {
			this.template.push({
				key: 'delete',
				element: (
					<DeactivateDropdownButton
						userId={id}
						onDeactivated={onDelete}
					/>
				),
			});
		}

		return this;
	}

	public addButton(button: React.ReactElement | null, display: boolean = true): ActionMenuBuilder {
		if (display) {
			this.template.push({
				key: 'update',
				element: button,
			});
		}

		return this;
	}
}
