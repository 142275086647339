import React from 'react';

import Input from 'antd/lib/input';
import { Field, FieldProps } from 'formik';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { FormValues } from '@app/components/Various/PriceEditor/PriceEditor';
import { Stack, StackDirection } from '@app/components/Layout/Stack';

export const EngravingPriceComponent: React.FC = () => (
	<Stack
		direction={StackDirection.Horizontal}
		className="row stack-container"
		gap={0}
	>
		<Field name="value">
			{(fieldProps: FieldProps<FormValues>) => (
				<FormikInput
					fieldProps={fieldProps}
					title="Value*"
					containerClassName="col-sm-6"
					render={(formik: FieldProps) => (
						<Input
							value={formik.field.value}
							onChange={(event) => formik.form.setFieldValue(formik.field.name, event.target.valueAsNumber)}

							type="number"
							min={0}
							inputMode="decimal"
						/>
					)}
				/>
			)}
		</Field>
		<Field name="maxSize">
			{
				(fieldProps: FieldProps<FormValues>) => (
					<FormikInput
						fieldProps={fieldProps}
						title="Maximal line length"
						containerClassName="col-sm-6"
						render={(formik: FieldProps) => (
							<Input
								value={formik.field.value}
								onChange={(event) => formik.form.setFieldValue(formik.field.name, event.target.valueAsNumber)}

								type="number"
								min={0}
								inputMode="decimal"
							/>
						)}
					/>
				)
			}
		</Field>
	</Stack>
);
