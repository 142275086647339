import * as React from 'react';

import Modal from 'antd/lib/modal';

import RequestComponent, { RequestData } from '@common/react/smart components/RequestComponent';
import Button from '@common/react/components/Forms/Button';
import { ErrorMessageComponent, SuccessMessageComponent } from '@common/react/components/Messages/MessageComponent';

interface OwnProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	allowResend?: boolean;
	userId?: number;
	minified?: boolean;
	email: string;
}

interface EmailResponse {
	email: string;
}

export const EmailInputDropdownButton: React.FC<OwnProps> = (props: OwnProps) => {
	const {
		allowResend = false, userId = -1, minified = false, email,
	} = props;

	const button = (isLoading: boolean, action: () => void) => (
		<Button
			className="btn btn-transparent"
			title="Resend Confirmation Email"
			isLoading={isLoading}
			type="button"
			onClick={() => Modal.confirm({
				title: `Send confirmation email to ${email}?`,
				onOk: action,
			})}
			disabled={!allowResend || userId <= 0}
		>
			<i className="fa fa-envelope" aria-hidden="true" />
			<span className="icon-margin">Resend Confirmation Email</span>
		</Button>
	);

	return (
		<RequestComponent endpoint="resendConfirmationEmail" requestParams={{ id: userId }}>
			{
				(data: RequestData<EmailResponse>) => (
					<>
						<SuccessMessageComponent message={data.item ? `Confirmation email was resent to ${data.item.email}` : null} />
						<ErrorMessageComponent message={data.error}>
							{
								(minified
								&& button(data.isLoading, data.request)) || (
									<div className="input-group">
										<input {...props} />
										<div className="input-group-btn">
											{button(data.isLoading, data.request)}
										</div>
									</div>
								)
							}
						</ErrorMessageComponent>
					</>
				)
			}
		</RequestComponent>
	);
};
