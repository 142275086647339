import { InventoryItem } from '@app/objects/Inventory';

type WithFullName = Pick<InventoryItem, 'fullName'>;

function toFullName(item: WithFullName | string): string {
	if (typeof item === 'string') return item;

	return item.fullName;
}

function toComponents(item: WithFullName | string): Array<string> {
	const fullname = toFullName(item);

	return fullname.split('/');
}

/**
 * Format Inventory Item fullname from path-format to Entry (attributes) format
 * @param item
 */
export function formatFullName(item: WithFullName | string): string {
	const components = toComponents(item);

	if (components.length === 0) return '';
	if (components.length === 1) return components[0];

	const [root, ...attributes] = components;

	return `${root} (${attributes.join(', ')})`;
}

export function getRootName(item: WithFullName | string): string {
	const fullname = toFullName(item);
	const components = fullname.split('/');

	if (components.length === 0) return '';

	return components[0];
}

export function getReadableName(item: WithFullName | string): string {
	const components = toComponents(item);

	if (components.length === 0) return '';
	if (components.length === 1) return components[0];

	const [root, ...attributes] = components;

	return `${attributes.join(' ')} ${root}`;
}
