import * as React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'antd/lib/tooltip';
import Button from 'antd/lib/button';

import { ApplicationState } from '@app/store';

export enum Method {
	GET = 'GET',
	POST = 'POST',
}

interface ExportButtonProps {
	endpoint: string;
	contentType: string;
	method?: Method;

	className?: string;
	disabled?: boolean;

	title?: string;
	children?: React.ReactNode;

	data?: Record<string, unknown> | null;

	onReceive: (response: Blob) => void;
	onError: (error: string) => void;
}

export const ExportButton: React.FC<ExportButtonProps> = (props: ExportButtonProps) => {
	const { method = Method.GET } = props;
	const session = useSelector((state: ApplicationState) => state.login.session);
	const [loading, setLoading] = React.useState<boolean>(() => false);

	const info: RequestInit = {
		method,
		credentials: 'same-origin',
		headers: {
			'Content-Type': props.contentType,
			Cookie: `session=${session ?? ''}`,
		},
		referrerPolicy: 'no-referrer',
	};

	if (props.data) Object.assign(info, { body: JSON.stringify(props.data) });

	const onSubmit = () => {
		setLoading(true);
		// eslint-disable-next-line compat/compat
		fetch(props.endpoint, info)
			.then((response: Response) => response.blob())
			.then(props.onReceive)
			.catch(props.onError)
			.finally(() => setLoading(false));
	};

	return (
		<>
			<span className="btn--add-with-icon">
				<Tooltip title={props.title}>
					<Button
						type="primary"
						icon="file-excel"
						size="default"
						onClick={onSubmit}
						loading={loading}
						disabled={props.disabled}
						className={props.className}
					/>
					<span className="visually-hidden">{props.children}</span>
				</Tooltip>
			</span>
			<span className="btn--add-with-word">
				<Button
					type="primary"
					onClick={onSubmit}
					loading={loading}
					disabled={props.disabled}
					className={props.className}
				>
					<span>{props.children}</span>
				</Button>
			</span>
		</>
	);
};

ExportButton.defaultProps = {
	children: 'Export',
	data: null,
};
