import * as React from 'react';

import { request } from '@common/react/components/Api';
import Button from '@common/react/components/Forms/Button';
import { BaseUser } from '@common/react/objects/BaseUser';

interface TransmutationProps {
	user: BaseUser;
	title: string;
	disabled?: boolean;
	onSuccess?: () => void;
	onError?: () => void;
}

interface TransmutationState {
	isLoading: boolean;
	transmuted: boolean;
}

export class TransmutationButton extends React.Component<TransmutationProps, TransmutationState> {
	state = {
		isLoading: false,
		transmuted: false,
	};

	constructor(props: TransmutationProps&TransmutationState) {
		super(props);
		this.handlerClick = this.handlerClick.bind(this);
	}

	handlerClick(): void {
		this.setState({ isLoading: true });
		const onSuccess = this.props.onSuccess || window.location.reload.bind(window.location);
		const onError = this.props.onError || window.location.reload.bind(window.location);

		request('transmutation', {
			id: this.props.user.id,
			transmutation: !this.state.transmuted,
		}).then(() => {
			onSuccess();
		}).catch(() => {
			// console.error(`Transmutation error: ${error}`);
			onError();
		});
	}

	render(): JSX.Element {
		return (
			<Button
				onClick={this.handlerClick}
				isLoading={this.state.isLoading}
				className="btn btn-transparent"
				title={this.props.title}
				disabled={this.props.disabled}
			>
				<i className="fa fa-child" aria-hidden="true" />
				<span className="icon-margin">Transmute</span>
			</Button>
		);
	}
}
