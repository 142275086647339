/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import * as React from 'react';
import { Drawer, Affix } from 'antd';

import { SortingDirection } from '@common/react/components/Pages/ItemsPage';
import { TableRemoteSorterData } from '@common/react/smart components/Table/TableRemoteSorter';
import { WithId } from '@common/typescript/objects/WithId';

import '@app/scss/ui/filters-component.scss';
import { getFilters, onChangeValue } from '@app/components/UI/Filters/FiltersList';
import { Filters } from '@app/components/UI/Filters/FilterHook';

interface MobileButtonsProps {
	isSorterOpened: boolean;
	setSorterOpened: (v: boolean) => void;
	isFilterOpened: boolean;
	setOpenedFilter: (v: boolean) => void;
	filtersAmount: number;
	direction: number;
}

interface MobileSorterProps<T extends WithId> {
	sorterNames: Array<string>;
	isOpened: boolean;
	setOpened: (v: boolean) => void;
	sorter: TableRemoteSorterData<T>|undefined;
	direction: number;
	setDirection: (value: number) => void;
}

interface MobileFiltersProps {
	filterNames: Array<string>;
	isOpened: boolean;
	setOpened: (v: boolean) => void;
	setQueryParams: (value: Partial<Filters>) => void;
	onChange: (name: string, value: onChangeValue) => void;
	onDeselect: (fieldName: string, type: string, id?: number | undefined) => void;
	filters: Partial<Filters>;
	clearQueryParams: () => void;
}

interface MobileFilterBarProps<T extends WithId> {
	filters: Partial<Filters>;
	filterNames: Array<string>;
	sorterNames: Array<string>;
	onChange: (name: string, value: onChangeValue) => void;
	onDeselect: (fieldName: string, type: string, id?: number | undefined) => void;
	setQueryParams: (value: Partial<Filters>) => void;
	clearQueryParams: () => void;
	sorter: TableRemoteSorterData<T>|undefined;
}

enum SorterNames {
	name='name',
	id='id',
	ownerFirstName='owner first name',
	ownerLastName='owner last name',
	crematories='crematory',
	contactPerson='contact person',
	petSpecie='specie',
	petBreed='breed',
	clinic='clinic',
	deliveryType='delivery type',
	email='email',
	phone='phone',
	role='role',
	crematoryRole='role',
	serviceType='service type',
	serviceTypeWithAll='service type',
	status='status',
	invoice='invoice',
	date='date',
	priceType='price type',
	discountStatus='discount status',
	specialServices='special service',
	enabled='status',
	invoiceStatus='status',
	orderNumber='order number',
	dueDate='due date',
}

const SortingIcon: React.FC<{direction: number}> = ({ direction }): JSX.Element => (
	<svg
		width="16"
		height="16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{ transform: direction === SortingDirection.Descending ? 'scaleY(-1)' : '' }}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 11a1 1 0 100 2h10a1 1 0 100-2H3zm0-4a1 1 0 100 2h6.668a1
								1 0 100-2H3zM2 4a1 1 0 011-1h3.333a1 1 0 110 2H2.999a1 1 0 01-1-1z"
			fill="currentColor"
		/>
	</svg>
);

export const FilterIcon: React.FC = (): JSX.Element => (
	<svg
		width="16"
		height="16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5 5a1 1 0 11-2 0 1 1 0 012 0zm1.834.986A3.001 3.001 0
		011 5a3 3 0 015.834-.986C6.888 4.004 6.944 4 7 4h7a1 1 0 110 2H7c-.057 0-.112-.005-.166-.014zM13 11a1 1
		0 11-2 0 1 1 0 012 0zm-1 3a3 3 0 10-2.834-3.986A1.008 1.008 0 009 10H2a1 1 0 100 2h7c.057 0 .112-.005.166-.014A3.001 3.001 0 0012 14z"
			fill="currentColor"
		/>
	</svg>
);

export const MobileButtons: React.FC<MobileButtonsProps> = (props: MobileButtonsProps): JSX.Element => {
	const {
		isSorterOpened, setSorterOpened, isFilterOpened, setOpenedFilter, filtersAmount, direction,
	} = props;

	return (
		<Affix className="mobile-filter-buttons">
			<div className="mobile-filter-buttons__inner">
				<button
					type="button"
					className="btn filter-mobile-btn"
					onClick={() => setSorterOpened(!isSorterOpened)}
				>
					<span>Sorters</span>
					<SortingIcon direction={direction} />
				</button>
				<button
					type="button"
					className="btn filter-mobile-btn"
					onClick={() => setOpenedFilter(!isFilterOpened)}
				>
					<span>Filters</span>
					{filtersAmount > 0 ? <span className="mobile-filters__quantity">{filtersAmount}</span> : <FilterIcon />}
				</button>
			</div>
		</Affix>

	);
};

export const MobileSorterComponent = <T extends WithId>(props: MobileSorterProps<T>): JSX.Element => {
	const {
		sorterNames, isOpened, setOpened, sorter, direction, setDirection,
	} = props;
	const [propertyName, setPropertyName] = React.useState<string|null>(null);

	return (
		<Drawer
			title="Sort"
			placement="bottom"
			closable={false}
			onClose={() => setOpened(false)}
			visible={isOpened}
			headerStyle={{ display: 'none' }}
			height={300}
		>
			<div className="filter-outer-wrap--mobile">

				<div className="sorter-panel__icon">
					<svg id="closeLine" height="4" width="32" xmlns="http://www.w3.org/2000/svg">
						<rect className="icon__fill" fill="#333" fillRule="evenodd" height="4" rx="2" width="32" />
					</svg>
				</div>
				<div className="filter-panel__heading">
					<span className="sorter-panel__heading-name">Sort by</span>
					<button
						type="button"
						className="sorter-panel__heading-type btn btn-transparent"
						onClick={() => {
							if (!propertyName) {
								if (direction === SortingDirection.Ascending) {
									setDirection(SortingDirection.Descending);
								} else {
									setDirection(SortingDirection.Ascending);
								}
							} else {
								sorter?.setSorter({
									column: {},
									order: direction === SortingDirection.Ascending ? 'descend' : 'ascend',
									field: propertyName ?? '',
									columnKey: propertyName ?? '',
								});
							}
							setDirection(direction === SortingDirection.Ascending ? SortingDirection.Descending : SortingDirection.Ascending);
						}}
					>
						{direction === SortingDirection.Descending && 'Descending'}
						{direction === SortingDirection.Ascending && 'Ascending'}
						<SortingIcon direction={direction} />
					</button>
				</div>

				<ul className="sorter-panel__items">
					{sorterNames.map((elm) => {
						if (elm !== 'excludingStatus') {
							return (
								<li className="sorter-panel__element-wrap" key={elm}>
									<input
										type="checkbox"
										id={elm}
										className="sorter-panel__checkbox"
										onChange={() => {
											if (propertyName === elm) {
												setPropertyName('');
												sorter?.setSorter(undefined);
											} else {
												setPropertyName(elm);
												sorter?.setSorter({
													column: {},
													order: direction === 1 ? 'ascend' : 'descend',
													field: elm,
													columnKey: elm,
												});
											}
										}}
										checked={propertyName === elm}
									/>
									<label htmlFor={elm} className="sorter-panel__element">By {SorterNames[elm]}</label>

								</li>
							);
						}

						return null;
					})}
				</ul>

			</div>
		</Drawer>
	);
};

export const MobileFilterComponent: React.FC<MobileFiltersProps> = ({
	filterNames, isOpened, setOpened, setQueryParams, onChange, onDeselect, filters, clearQueryParams,
}): JSX.Element => {
	const handleSubmit = () => setQueryParams(filters);
	const allFilterFields = getFilters(filters, onChange, onDeselect, handleSubmit);

	return (
		<Drawer
			title="Filters"
			placement="right"
			closable
			onClose={() => setOpened(false)}
			visible={isOpened}
			width="100%"
			className="mobile-filters"
		>
			<div className="mobile-filters__inner">
				{filterNames.map((elm) => (
					<div
						className="filter-element"
						key={elm}
					>
						{allFilterFields[elm]}
					</div>
				))}
			</div>

			<div className="filter-panel__action">
				<button
					type="button"
					className="btn btn-link"
					onClick={() => {
						setOpened(!isOpened);
						clearQueryParams();
					}}
				>Reset filters
				</button>
				<button
					type="button"
					className="btn btn-primary"
					onClick={() => {
						setOpened(!isOpened);
						handleSubmit();
					}}
				>Search
				</button>
			</div>
		</Drawer>
	);
};

export const MobileFiltersBar = <T extends WithId>(props: MobileFilterBarProps<T>): JSX.Element => {
	const {
		filters, filterNames, onChange, onDeselect, setQueryParams, clearQueryParams, sorter, sorterNames,
	} = props;
	const [isSorterOpened, setSorterOpened] = React.useState<boolean>(false);
	const [isFilterOpened, setFilterOpened] = React.useState<boolean>(false);
	const [direction, setDirection] = React.useState<number>(SortingDirection.Descending);

	return (
		<>
			<MobileButtons
				isSorterOpened={isSorterOpened}
				setSorterOpened={setSorterOpened}
				isFilterOpened={isFilterOpened}
				setOpenedFilter={setFilterOpened}
				filtersAmount={Object.keys(filters).length}
				direction={direction}
			/>
			<MobileSorterComponent
				sorterNames={sorterNames}
				isOpened={isSorterOpened}
				setOpened={setSorterOpened}
				sorter={sorter}
				direction={direction}
				setDirection={setDirection}
			/>
			<MobileFilterComponent
				filterNames={filterNames}
				isOpened={isFilterOpened}
				setOpened={setFilterOpened}
				onChange={onChange}
				onDeselect={onDeselect}
				setQueryParams={setQueryParams}
				filters={filters}
				clearQueryParams={clearQueryParams}
			/>
		</>
	);
};
