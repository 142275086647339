import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { IWithClass } from '@app/components/Interfaces/IWithClass';

interface BackButtonProps {
	backPath: string;
}

type GoBackLinkProps = React.PropsWithChildren<IWithClass & BackButtonProps>
const GoBackLink: React.FC<GoBackLinkProps> = (props: GoBackLinkProps) => (
	<Link
		className={props.className}
		to={props.backPath}
	>
		{props.children}
	</Link>
);

type GoBackButtonProps = React.PropsWithChildren<IWithClass>
const GoBackButton: React.FC<GoBackButtonProps> = (props: GoBackButtonProps) => {
	const history = useHistory();

	return (
		<a
			className={props.className}
			onClick={() => history.goBack()}
		>
			{props.children}
		</a>
	);
};

type ContainerProps = React.PropsWithChildren<IWithClass & BackButtonProps>;
const Container: React.FC<Partial<ContainerProps>> = (props: Partial<ContainerProps>) => {
	return (
		props.backPath
			? <GoBackLink backPath={props.backPath} className={props.className}>{props.children}</GoBackLink>
			: <GoBackButton className={props.className}>{props.children}</GoBackButton>

	);
};

export const BackButton: React.FC<Partial<BackButtonProps>> = (props: Partial<BackButtonProps>): JSX.Element => {
	return (
		<Container className="back-button" backPath={props.backPath}>
			<i className="fa fa-angle-left" aria-hidden="true" />
			<span>Back</span>
		</Container>
	);
};
