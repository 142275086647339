import * as React from 'react';

import { Field, FieldProps } from 'formik';

import { FormikInput, FormikInputRenderFunc } from '@common/react/components/Forms/FormikInput/FormikInput';

export interface FormikFieldProps {
	fieldName: string;
	title?: string;
	render?: FormikInputRenderFunc;
	containerClass?: string;
	replaceContainerClass?: boolean;
	inputProps?: React.HTMLProps<HTMLInputElement>;
}

export const FormikField = (props: FormikFieldProps): JSX.Element => {
	const containerClass = props.replaceContainerClass
		? props.containerClass || ''
		: `form-group ${props.containerClass}`;

	return (
		<Field name={props.fieldName}>
			{(fieldProps: FieldProps) => (
				<FormikInput
					fieldProps={fieldProps}
					containerClassName={containerClass}
					title={props.title}
					render={props.render}
					inputProps={props.inputProps}
				/>
			)}
		</Field>
	);
};
