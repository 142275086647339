import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { WithId } from '@common/typescript/objects/WithId';
import { getShallowChanges } from '@common/typescript/utils/entity';

import { request } from '@app/components/Api';
import { ModalWrapper } from '@app/components/UI/Modal/Modal';
import { toUpperCase } from '@app/components/Utils/Utils';
import { alertMessage, MessageType } from '@app/utilities/alert';
import { CompleteHandler, PartialId } from '@app/store/SelectList/UtilityTypes';

interface Props<T extends WithId> {
	createEndpoint: string;
	updateEndpoint: string;
	getEndpoint?: string;
	requestParams?: Record<string, unknown>;
	itemName: string;

	onCompleted?: CompleteHandler<T>;

	handleClose: () => void;
	visible: boolean;
	reload: () => void;

	validationSchema: Yup.ObjectSchema<Record<string, unknown>>;
	initialValues: T;
	getInitialValues: (item: T) => T;
	children: React.ReactNode;
	getChanges?: (origin: T, updated: T) => PartialId<T>;
	formatCreatingData?: (updated: T) => PartialId<T>;

	modalItemId: number | string;
	width?: string | number;
}

interface HandleSubmitProps<T extends WithId> {
	values: T;
	actions: FormikHelpers<T>;
	initial: T;
	handleClose: () => void;
	reload: () => void;
	itemName: string;
	createEndpoint: string;
	updateEndpoint: string;
	getChanges?: (origin: T, updated: T) => PartialId<T>;
	formatCreatingData?: (updated: T) => PartialId<T>;

	onCompleted?: CompleteHandler<T>;
}

const NEW_UNIT_ID = -1;

function getValues<T extends WithId>(
	initial: T,
	values: T,
	getChanges: (origin: T, updated: T) => PartialId<T>,
	formatCreatingData?: (updated: T) => PartialId<T>,
) {
	let changes;

	if (values.id === -1) {
		changes = formatCreatingData ? formatCreatingData(values) : values;
	} else {
		changes = getChanges(initial, values) as T;
	}

	return changes;
}

function handleSubmit<T extends WithId>(props: HandleSubmitProps<T>) {
	const {
		values,
		actions,
		handleClose,
		reload,
		itemName,
		createEndpoint,
		updateEndpoint,
		getChanges = getShallowChanges,
		formatCreatingData,

		onCompleted,
	} = props;

	const upperCasedItem = toUpperCase(itemName);

	const { endpoint, messageText } = values.id === NEW_UNIT_ID
		? { endpoint: createEndpoint, messageText: `Success! ${upperCasedItem} was created` }
		: { endpoint: updateEndpoint, messageText: `Success! ${upperCasedItem} was updated` };

	request<T, T>(endpoint, getValues(props.initial, values, getChanges, formatCreatingData))
		.then((item: T) => {
			onCompleted?.(item, values);
			alertMessage(MessageType.success, messageText);
			reload();
			handleClose();
		})
		.catch((err) => alertMessage(MessageType.error, `Error! ${err}`))
		.finally(() => actions.setSubmitting(false));
}

export const ItemPageEditor = <T extends WithId>(props: Props<T>): React.ReactElement => {
	const {
		handleClose,
		visible,
		reload,
		initialValues,
		getInitialValues,
		validationSchema,
		itemName,
		createEndpoint,
		updateEndpoint,
		getEndpoint,
		getChanges,
		formatCreatingData,
		modalItemId,
		requestParams = {},

		onCompleted,
		width = 520,
	} = props;

	const { t } = useTranslation();
	const [initial, setInitial] = React.useState<T>(initialValues);

	React.useEffect(() => {
		setInitial(initialValues);
	}, [initialValues]);

	React.useEffect(() => {
		if (modalItemId && Number(modalItemId) > -1 && getEndpoint) {
			request<T>(getEndpoint, { ...requestParams, id: modalItemId })
				.then((response: T) => setInitial(getInitialValues(response)))
				.catch((error: string) => alertMessage(MessageType.error, error));
		} else { setInitial(initialValues); }
	}, [modalItemId]);

	const onSubmit = (values: T, actions: FormikHelpers<T>) =>
		handleSubmit<T>({
			values, actions, initial, handleClose, reload, itemName, createEndpoint, updateEndpoint, getChanges, formatCreatingData, onCompleted,
		});
	const getOkText = (id: number): string => (id === NEW_UNIT_ID ? t('buttons.add') : t('buttons.save'));
	const getTitle = (id: number): string => (id === NEW_UNIT_ID ? `${t('buttons.add')} ${itemName}` : `${t('buttons.edit')} ${itemName}`);

	return (
		<Formik
			initialValues={initial}
			onSubmit={onSubmit}
			enableReinitialize
			validationSchema={validationSchema}
		>
			{(formikBag: FormikProps<T>) => (
				<ModalWrapper
					title={getTitle(formikBag.values.id)}
					isOpened={visible}
					onCancel={() => {
						formikBag.resetForm();
						handleClose();
					}}
					onOk={() => formikBag.handleSubmit()}
					cancelText={t('buttons.cancel')}
					okText={getOkText(formikBag.values.id)}
					width={width}
					okButtonProps={{ loading: formikBag.isSubmitting }}
				>
					{props.children}
				</ModalWrapper>
			)}
		</Formik>
	);
};
