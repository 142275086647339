import * as React from 'react';

import { LengthUnit } from '@app/objects/LabelPaper';
import { unitName } from '@app/components/Pages/Admin/LabelPaper/LabelPaperList';

interface DimensionLabelProps {
	value: number;
	unit: LengthUnit;
	title?: string;
}

type OwnProps = React.PropsWithChildren<DimensionLabelProps>;

export const DimensionLabel: React.FC<OwnProps> = (props: OwnProps) => {
	return (
		<span title={props.title}>
			{props.children} ({props.value.toFixed(1)} {unitName(props.unit)})
		</span>
	);
};
