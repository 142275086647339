import { LabelPaper } from '@app/objects/LabelPaper';

export class PaperManager {
	private readonly _paper: LabelPaper;

	constructor(paper: LabelPaper) {
		this._paper = paper;
	}

	public static for(paper: LabelPaper) {
		return new PaperManager(paper);
	}

	public get cellWidth(): number {
		const grid = this._paper.width - this._paper.margins.left - this._paper.margins.right;
		const columns = grid - (this._paper.columns - 1) * this._paper.spacing.column;

		return columns / this._paper.columns;
	}

	public get cellHeight(): number {
		const grid = this._paper.height - this._paper.margins.top - this._paper.margins.bottom;
		const rows = grid - (this._paper.rows - 1) * this._paper.spacing.row;

		return rows / this._paper.rows;
	}

	public get labelWidth(): number {
		return this.cellWidth - this._paper.labelMargins.left - this._paper.labelMargins.right;
	}

	public get labelHeight(): number {
		return this.cellHeight - this._paper.labelMargins.top - this._paper.labelMargins.bottom;
	}

	public get contentWidth(): number {
		return this.labelWidth - this._paper.labelPaddings.left - this._paper.labelPaddings.right;
	}

	public get contentHeight(): number {
		return this.labelHeight - this._paper.labelPaddings.top - this._paper.labelPaddings.bottom;
	}
}
