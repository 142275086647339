import React from 'react';
import Tag from 'antd/lib/tag';

import { Nullable } from '@common/typescript/objects/Nullable';
import { AlertMessage } from '@common/react/components/UI/AlertMessage/AlertMessage';

import { Loader } from '@app/components/UI/Buttons/ExportButtons/ExportSettings/Loader';

interface ButtonMenuProps {
	pending: boolean;
	loading: boolean;
	error: Nullable<string>;
	children: React.ReactNode;
}

export const ButtonMenu: React.FC<ButtonMenuProps> = (props: ButtonMenuProps) => (
	<>
		<div
			className="form-group vertical-spacing loading-container"
			style={{ maxHeight: '65vh', overflow: 'auto', minHeight: '64px' }}
		>
			{props.children}

			<Loader loading={props.loading} />
			<div className="form-row">
				<AlertMessage message={props.error} />
			</div>
		</div>
		<div className="form-row clearfix">
			<span className="pull-left">State:</span>
			<span className="pull-right">
				<Tag
					color={props.pending ? 'orange' : 'green'}
					style={{ margin: 0 }}
				>
					{props.pending ? 'Pending' : 'Up to date'}
				</Tag>
			</span>
		</div>
	</>
);
