import moment from 'moment';

import { DateRange, Filters } from '@app/components/UI/Filters/FilterHook';

const format = 'YYYY-MM-DD';

export function getQueryParams(filters: Partial<Filters>) {
	const params: Array<string> = [];
	Object.entries(filters).forEach(([key, value]) => {
		if (Array.isArray(value)) params.push(value.map((i) => (`${key}=${i}`)).join('&'));

		const isDate = key === 'date' || key === 'dueDate';
		if (isDate) {
			const item: DateRange = value;
			params.push(`${key}.from=${moment(item.from).format(format)}&${key}.to=${moment(item.to).format(format)}`);
		}

		if (!Array.isArray(value) && !isDate) params.push(`${key}=${value}`);
	});

	return params.join('&');
}
