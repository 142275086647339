import * as React from 'react';

import Upload from 'antd/lib/upload';
import Icon from 'antd/lib/icon';

import Button from '@common/react/components/Forms/Button';
import {
	FileType,
	FileInterface,
} from '@common/typescript/objects/FileInterface';

import { Nullable } from '@common/typescript/objects/Nullable';
import { member } from '@common/react/utils/decorators';
import { request } from '@common/react/components/Api/Request';
import {
	upload,
	TransformData,
} from '@common/react/components/Forms/Files/Upload';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';

interface FileState {
	isLoading: boolean;
	error: Nullable<string>;
}

interface FileProps<TData = null> {
	type: string;
	objectId: number;
	onUpdate: (data: FileInterface) => void;
	onError?: (error: string) => void;
	fileType?: FileType;

	infoMessage?: string;
	showError?: boolean;
	asButton?: boolean;
	accept?: string;
	buttonCaption?: string | JSX.Element;
	buttonClassName?: string;
	disabled?: boolean;
	multiple?: boolean;
	noRelation?: boolean; // Whether to create relation for uploaded file or not
	data?: TData;
	transformData?: TransformData; // Transform data before sending to server
}

export class File<TData = null> extends React.Component<FileProps<TData>, FileState> {
	public static defaultProps: Partial<FileProps> = {
		infoMessage: 'Image size must not exceed 1 Mb',
		asButton: false,
		accept: '',
		buttonCaption: 'Upload',
		showError: true,
	};

	public state: FileState = {
		isLoading: false,
		error: null,
	};

	@member
	customRequest(argument: RcCustomRequestOptions) {
		const { file, headers } = argument;

		const {
			type,
			objectId,
			fileType = FileType.Avatar,
			data,
			noRelation,
			transformData,
		} = this.props;

		if (noRelation) {
			headers['No-Relation'] = 'true';
		}

		this.setState({
			isLoading: true,
			error: null,
		});

		upload<TData>({
			file,
			fileType,
			objectId,
			type,
			data,
			headers: headers as HeadersInit,
		}, transformData)
			.then((file: FileInterface) => {
				this.setState({ isLoading: false });
				this.props.onUpdate(file);
			}).catch((error) => {
				this.setState({
					isLoading: false,
					error: error.message,
				});

				if (this.props.onError) {
					this.props.onError(error.message);
				}
			});
	}

	render(): JSX.Element {
		const {
			asButton,
			accept,
			infoMessage,
			buttonCaption,
			buttonClassName,
		} = this.props;

		let uploadButton;

		if (asButton) {
			uploadButton = (
				<>
					<Button
						type="button"
						className={buttonClassName}
						isLoading={this.state.isLoading}
						disabled={this.props.disabled}
					>
						{buttonCaption}
					</Button>
					{this.props.showError !== false && <div className="file-upload-error">{this.state.error}</div>}
				</>
			);
		} else {
			uploadButton = (
				<>
					<Icon type={this.state.isLoading ? 'loading' : 'plus'} />
					<div className="ant-upload-text">{buttonCaption}</div>
					<div className="ant-upload-info">{this.state.error || infoMessage }</div>
				</>
			);
		}

		return (
			<Upload
				className={`avatar-uploader ${this.state.error ? 'avatar-uploader_error' : ''}`}
				listType={this.props.asButton ? 'text' : 'picture-card'}
				showUploadList={false}
				accept={accept}
				customRequest={this.customRequest}
				disabled={this.props.disabled}
				multiple={this.props.multiple}
			>
				{uploadButton}
			</Upload>
		);
	}
}

type ResultType<TResponse> = TResponse & { file: FileInterface };
interface CustomFileProps<TResponse, TData = null> extends FileProps<TData> {
	onUploadType: string;
	onUpload: (data: ResultType<TResponse>) => void;
}

export class CustomFile<TResponse, TData = null> extends React.Component<CustomFileProps<TResponse, TData>> {
	@member
	onUpdate(data: FileInterface) {
		request<TResponse, BaseUser, BaseApplicationState<BaseUser>>(this.props.onUploadType, {
			fileId: data.id,
		}).then((response: TResponse) => {
			// response cast to any for new typescript support
			const image: ResultType<TResponse> = {
				...response,
				file: data,
			};

			this.props.onUpload(image);
		}).catch(() => {
			debugger;
		});
	}

	render() {
		return <File {...this.props} onUpdate={this.onUpdate} />;
	}
}
