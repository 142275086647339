import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
	Field, FieldProps, Form, useFormikContext,
} from 'formik';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Search from 'antd/lib/input/Search';
import Table, { PaginationConfig } from 'antd/lib/table';

import { List } from '@common/typescript/objects/List';
import { Nullable } from '@common/typescript/objects/Nullable';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { MobileCell } from '@common/react/components/Pages/ItemsPage';

import {
	Price, PriceKind, PriceType,
} from '@app/objects/Price';

import { DeliveryType, ServiceType } from '@app/objects/Pet';
import { QuickbooksItemLink, QuickbooksSetItems } from '@app/objects/Quickbooks';
import { LocalSelect } from '@app/components/UI/Inputs/LocalSelect';
import { useCrematory } from '@app/hooks/useCrematory';
import {
	serviceTypes, priceType as priceTypeOptions, priceKindOptions,
} from '@app/components/UI/Inputs/LocalSelectOptions';
import { ClinicSelect } from '@app/components/UI/Inputs/ClinicSelect';
import { useRequest } from '@app/hooks/useRequest';
import { useDebounce } from '@app/hooks/useDebounce';
import { columns } from '@app/components/Various/PriceList/columns';
import { Clinic } from '@app/objects/Clinic';
import { getClinicViewPath } from '@app/components/Pages/Clinics/ClinicApi';
import { WeightUnits } from '@app/objects/Crematory';

const pageSize = 10;

interface RequestParams {
	filters: {
		name: string;
		priceType: Array<PriceType>;
		serviceType: Array<ServiceType>;
		priceKind: PriceKind;
		clinic: Array<Nullable<number>>;
	};
	count: number;
	offset: number;
}

const noClinicId = -13;
const local = () => ([
	{
		id: noClinicId,
		name: 'No Clinic',
		countryId: 0,
		isCustomer: false,
		defaultPrivateDeliveryType: DeliveryType.Clinic,
		defaultSemiPrivateDeliveryType: DeliveryType.Clinic,
		defaultCommunalDeliveryType: DeliveryType.Clinic,
		defaultStorageDeliveryType: DeliveryType.Clinic,
	},
]);
const getClinicIdValues = (clinics: Array<Nullable<number>>) => {
	return clinics.map((id: Nullable<number>) => {
		if (id === null) return noClinicId;

		return id;
	});
};
const getClinicId = (clinics: Array<number>) => {
	return clinics.map((id: Nullable<number>) => {
		if (id === noClinicId) return null;

		return id;
	});
};
const addItem = (priceId: number) => ({ priceId, delete: false });
const checked = (selectAll: boolean, links: Array<QuickbooksItemLink>, priceId: number) => {
	let check = false;
	const item = links.find((i) => i.priceId === priceId);

	if ((selectAll && item) || (!selectAll && !item)) check = false;

	if ((selectAll && !item) || (!selectAll && item)) check = true;

	return check;
};

export const checkedColumn = TableBuilder.shape<Price>()
	.addColumn({
		title: (
			<Field name="selectAll">
				{({ field, form }: FieldProps<boolean, QuickbooksSetItems>) => (
					<Checkbox
						checked={field.value}
						onChange={(event: CheckboxChangeEvent) => {
							form.setFieldValue(field.name, event.target.checked);
							form.setFieldValue('links', []);
						}}
						indeterminate={Boolean(form.values.links.length)}
					/>
				)}
			</Field>
		),
		width: '10ch',
		align: 'center',
		dataIndex: 'check',
		render: (_, record: Price) => (
			<Field name="links">
				{({ field, form }: FieldProps<Array<QuickbooksItemLink>, QuickbooksSetItems>) => (
					<Checkbox
						checked={checked(form.values.selectAll, field.value, record.id)}
						onChange={(event: CheckboxChangeEvent) => {
							const isChecked = event.target.checked;

							if (!form.values.selectAll && isChecked) {
								form.setFieldValue(field.name, [...field.value, addItem(record.id)]);
							} else if (!form.values.selectAll && !isChecked) {
								form.setFieldValue(field.name, field.value.filter((i) => i.priceId !== record.id));
							}
						}}
					/>
				)}
			</Field>
		),
	})
	.build();

export const clinicColumn = TableBuilder.shape<Price>()
	.addColumn({
		title: 'Clinic',
		align: 'center',
		dataIndex: 'clinic',
		render: (clinic: Nullable<Clinic>) => (
			<MobileCell caption="Clinic">
				{clinic
					? (
						<div onClick={(e) => e.stopPropagation()}>
							<Link to={getClinicViewPath(clinic.id, 'general')} target="_blank">{clinic.name}</Link>
						</div>
					) : 'None'}
			</MobileCell>
		),
	})
	.build();

export function getColumns(priceKind: PriceKind, weightUnit?: WeightUnits) {
	return columns(weightUnit ?? WeightUnits.Kilograms)[priceKind];
}

export const InnerForm: React.FC = () => {
	const { t } = useTranslation('translation');
	const { setFieldValue } = useFormikContext<QuickbooksItemLink>();
	const [currentPage, setCurrentPage] = React.useState<number>(0);
	const [query, setQuery] = React.useState<string>('');
	const [serviceType, setServiceType] = React.useState<Array<ServiceType>>([]);
	const [priceType, setPriceType] = React.useState<Array<PriceType>>([]);
	const [priceKind, setPriceKind] = React.useState<PriceKind>(PriceKind.BasePrice);
	const [clinic, setClinic] = React.useState<Array<Nullable<number>>>([null]);
	const crematory = useCrematory();

	const reqParams = React.useMemo(() => ({
		withClinic: true,
		filters: {
			name: query,
			priceType,
			serviceType,
			priceKind,
			clinic,
		},
		count: pageSize,
		offset: pageSize * currentPage,
	}), [currentPage, query, priceKind, priceType, serviceType, clinic]);

	const data = useRequest<List<Price>, RequestParams>('quickbooksUnlinkedItemList', reqParams, { requestOnMount: false });

	const debounce = useDebounce((value: Record<string, unknown> | null) => {
		if (currentPage === 0) {
			data.reload({
				...reqParams,
				filters: {
					name: query,
					priceType,
					serviceType,
					priceKind,
					clinic,
					...value,
				},
			}).then(() => setFieldValue('filters', {
				priceType, serviceType, priceKind, clinic, ...value,
			}));
		} else {
			setCurrentPage(0);
		}
	}, 750);

	React.useEffect(() => {
		if (!data.loading) data.reload(reqParams);
	}, [currentPage]);

	return (
		<Form>
			<Field name="links">
				{({ field, form }: FieldProps<Array<QuickbooksItemLink>, QuickbooksSetItems>) => (
					<>
						<Search
							allowClear
							onChange={(event) => {
								if (event.target.value === '' && event.type === 'click' && setQuery) {
									setQuery('');
								}
							}}
							placeholder="Search by Name"
							onSearch={(e: string) => {
								setQuery(e);

								if (currentPage === 0) {
									data.reload({ ...reqParams, filters: { ...reqParams.filters, name: e }, offset: 0 });
								} else {
									setCurrentPage(0);
								}
							}}
							style={{ padding: '0 0 10px' }}
							enterButton={<i className="fa fa-search" />}
						/>
						<div className="row form-group">
							<LocalSelect
								value={serviceType}
								onChange={(value) => { setServiceType(value); debounce({ serviceType: value }); }}
								multiple
								options={serviceTypes(crematory)}
								deselectType="id"
								filterName="serviceType"
								fieldName="Service type"
								placeholder={t('filters.service-type')}
								className="col-sm-6"
							/>
							<LocalSelect
								value={priceType}
								onChange={(value) => { setPriceType(value); debounce({ priceType: value }); }}
								multiple
								options={priceTypeOptions(t)}
								deselectType="id"
								filterName="priceType"
								fieldName="Price type"
								placeholder={t('filters.price-type')}
								className="col-sm-6"
							/>
						</div>
						<div className="row form-group">
							<LocalSelect
								value={priceKind}
								onChange={(value) => {
									setPriceKind(value);
									if (form.values.selectAll) {
										setFieldValue('selectAll', false);
										form.setFieldValue('links', []);
									}

									data.reload({
										...reqParams,
										filters: {
											name: query,
											priceType,
											serviceType,
											priceKind: value,
											clinic,
										},
									}).then(() => setFieldValue('filters', {
										priceType, serviceType, priceKind, clinic,
									}));
								}}
								options={priceKindOptions}
								deselectType="id"
								filterName="priceKind"
								fieldName="Price Kind"
								placeholder={t('filters.price-kind')}
								className="col-sm-6"
							/>
							<ClinicSelect
								value={getClinicIdValues(clinic)}
								onChange={(ids?: Array<number>) => { setClinic(getClinicId(ids ?? [])); debounce({ clinic: ids }); }}
								allowClear={false}
								crematoryId={crematory?.id}
								localOptions={local()}
								multiple
								className="col-sm-6"
								placeholder={`${t('helpers.select')} ${t('table.columns.clinic')}`}
							/>
						</div>

						<Table
							columns={[...checkedColumn, ...getColumns(priceKind, crematory?.units.weight), ...clinicColumn]}
							dataSource={data.item?.list}
							onChange={(pagination: PaginationConfig) => {
								const page = (pagination.current ?? 1) - 1;
								setCurrentPage(page);
							}}
							pagination={{
								total: data.item?.count,
								pageSize,
								hideOnSinglePage: true,
								current: currentPage + 1,
							}}
							loading={data.loading}
							childrenColumnName="child"
							rowKey="id"
							className="table-mobile small-padding-table"
							onRow={(record) => ({
								onClick: () => {
									const links = form.values.links;

									if (links.find((i) => i.priceId === record.id)) {
										form.setFieldValue(field.name, links.filter((i) => i.priceId !== record.id));
									} else {
										form.setFieldValue(field.name, [...links, addItem(record.id)]);
									}
								},
							})}
						/>
					</>
				)}
			</Field>
		</Form>
	);
};
