import React from 'react';

import FormikField from '@common/react/components/Forms/FormikField/FormikField';

export interface CommonProps {
	disabled?: boolean;
	containerClassName?: string;
	placeholders?: {
		batchCount: string;
		batchPrice: string;
	}
}

export const BatchPriceFields: React.FC<CommonProps> = ({ disabled, containerClassName = 'col-sm-4', placeholders }: CommonProps) => (
	<>
		<FormikField
			fieldName="batchCount"
			title="Batch count"
			containerClassName={containerClassName}
			inputProps={{
				type: 'number',
				min: 0,
				inputMode: 'decimal',
				disabled,
				placeholder: placeholders?.batchCount,
			}}
		/>
		<FormikField
			fieldName="batchPrice"
			title="Batch price"
			containerClassName={containerClassName}
			inputProps={{
				type: 'number',
				min: 0,
				inputMode: 'decimal',
				disabled,
				placeholder: placeholders?.batchPrice,
			}}
		/>
	</>
);
