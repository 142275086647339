import * as React from 'react';

import Input from 'antd/lib/input';
import Tooltip from 'antd/lib/tooltip';

interface OwnProps {
	fieldName: string;
	title: string;
	placeholder?: string;
	value: string|undefined;
	className?: string;
	onChange: (text: string) => void;
	onDeselect: (fieldName: string, type: string, id?: number,) => void;
	submit: () => void;
	withFieldName?: boolean;
}

export const TextSearch: React.FC<OwnProps> = ({
	value, onChange, fieldName,
	className, onDeselect, title, submit, withFieldName, placeholder,
}):JSX.Element => (
	<div className={className}>
		{withFieldName && <span className="field-name">{title}</span>}
		<Input
			className={value && value.length > 0 ? 'with-inner-label--opened' : 'with-inner-label--closed'}
			addonBefore={value && value.length > 0 ? <span>{title}</span> : <span className="visually-hidden" />}
			placeholder={placeholder}
			value={value}
			onPressEnter={submit}
			onChange={(e) => {
				if (e.target.value === '') {
					onDeselect(fieldName, 'text');
				} else {
					onChange(e.target.value);
				}
			}}
			allowClear
		/>
	</div>
);

export const TextSearchTooltip: React.FC<OwnProps> = ({
	value, onChange, fieldName,
	className, onDeselect, title, submit, placeholder,
}): JSX.Element => (
	<div className={className}>
		<Tooltip
			title="A new search field. Now you can search by name/id/owner using a single field!"
			trigger="focus"
			placement="bottomLeft"
		>
			<Input
				className={value && value.length > 0 ? 'with-inner-label--opened' : 'with-inner-label--closed'}
				addonBefore={value && value.length > 0 ? <span>{title}</span> : <span className="visually-hidden" />}
				placeholder={placeholder}
				value={value}
				onPressEnter={submit}
				onChange={(e) => {
					if (e.target.value === '') {
						onDeselect(fieldName, 'text');
					} else {
						onChange(e.target.value);
					}
				}}
				allowClear
			/>
		</Tooltip>
	</div>
);
