import clsx from 'clsx';
import React from 'react';

interface ContainerProps {
	children: React.ReactNode;
	className?: string;
}

export const Container: React.FC<ContainerProps> = ({ children, className }: ContainerProps) => (
	<div className={clsx(className)}>
		{children}
	</div>
);
