import React from 'react';

import Input from 'antd/lib/input';
import { Field, FieldProps } from 'formik';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { FormValues } from '@app/components/Various/PriceEditor/PriceEditor';

export const RushFeePriceComponent: React.FC = () => (
	<Field name="value">
		{(fieldProps: FieldProps<FormValues>) => (
			<FormikInput
				fieldProps={fieldProps}
				title="Value*"
				containerClassName="col-sm-6"
				render={(formik: FieldProps) => (
					<Input
						value={formik.field.value}
						onChange={(event) => formik.form.setFieldValue(formik.field.name, event.target.valueAsNumber)}

						type="number"
						min={0}
						inputMode="decimal"
					/>
				)}
			/>
		)}
	</Field>
);
