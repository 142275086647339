import * as React from 'react';

import CommonContext, { Services } from '@common/react/components/ServiceContainer';
import { PaginationConfig } from 'antd/lib/pagination';

export interface RequestData<T> {
	request: (params?: object) => Promise<void>;
	isLoading: boolean;
	item: T | null;
	error: string | null;
}

interface OwnProps<T> {
	endpoint: string;
	requestParams?: object;
	children: (result: RequestData<T>) => JSX.Element;
	onCompleted?: () => void;
	requestOnMount?: boolean;
	requestOnParamsChanged?: boolean;
}

interface InjectedProps<T> {
	request: (endpoint: string, data?: any) => Promise<T>;
}

type ComponentProps<T> = OwnProps<T> & InjectedProps<T>;

function ConnectedRequestComponent<T>(props: ComponentProps<T>): JSX.Element {
	const {
		endpoint,
		requestParams,
		children,
		onCompleted,
		requestOnMount,
		requestOnParamsChanged,
		request,
	} = props;
	const [isLoading, setLoading] = React.useState(false);
	const [item, setItem] = React.useState<T | null>(null);
	const [error, setError] = React.useState<string | null>(null);
	const reqCount = React.useRef<number>(0);

	const requestAction = (params?: object) => {
		setLoading(true);
		setError(null);

		const promise = request(
			endpoint,
			{
				...requestParams,
				...params,
			},
		)
			.then(setItem)
			.catch(setError)
			.finally(() => setLoading(false));

		if (onCompleted) {
			promise.finally(onCompleted);
		}

		return promise;
	};

	React.useEffect(() => {
		// We don't need to depend on requestOnMount as we only need to know its value on mount ;)
		if (requestOnMount) {
			requestAction();
		}
	}, []);

	React.useEffect(() => {
		if (requestOnParamsChanged && reqCount.current > 0) {
			requestAction();
		}

		// We don't want to fire this effect on mount
		reqCount.current++;
	}, [requestOnParamsChanged, requestParams]);

	const data: RequestData<T> = {
		request: requestAction,
		item,
		isLoading,
		error,
	};

	return children(data);
}

function RequestComponent<T>(props: OwnProps<T>): JSX.Element {
	return (
		<CommonContext.Consumer>
			{
				(container: Services) => (
					<ConnectedRequestComponent request={container.request} {...props}>
						{props.children}
					</ConnectedRequestComponent>
				)
			}
		</CommonContext.Consumer>
	);
}

export default RequestComponent;
