import React from 'react';

import clsx from 'clsx';

import { isPresent, Nullable } from '@common/typescript/objects/Nullable';

import { LabelPaper, LabelPaperTypography, Spacing } from '@app/objects/LabelPaper';
import {
	ContainerPreview,
} from '@app/components/Pages/Admin/LabelPaper/LabelPaperEditor/LabelPaperEditorComponents/PreviewComponents/ContainerPreview';
import { LabelSkeleton } from '@app/components/Pages/Admin/LabelPaper/LabelPaperEditor/LabelPaperEditorComponents/PreviewComponents/LabelSkeleton';

const getArray = (count: number) => (Array(count).fill('Label').map((item: string, index: number) => `${item} ${index + 1}`));

interface PaperPreviewProps {
	/**
	 * LabelPaper for preview
	 */
	paper: LabelPaper;
	/**
	 * If present - mark all labels that are to be printed.
	 * Offset indicates how many labels would be skipped
	 */
	offset?: Nullable<number>;
	/**
	 * If present - changed offset value if u click on label.
	 */
	onChange?: (value: number) => void;
}

const showOffset = (index: number, mouseFilled: Nullable<number>, offset: number) => {
	let currentOffset = offset;

	if (isPresent(mouseFilled)) currentOffset = mouseFilled;

	return index >= currentOffset;
};

export const PaperPreview: React.FC<PaperPreviewProps> = (props: PaperPreviewProps) => {
	const { paper } = props;
	const {
		columns, rows,
	} = paper;
	const [elements, setElements] = React.useState<Array<string>>(getArray(columns * rows));
	const [mouseFilled, setMouseFilled] = React.useState<Nullable<number>>(null);

	React.useEffect(() => setElements(getArray(columns * rows)), [columns, rows]);

	return (
		<ContainerPreview
			paper={paper}
		>
			{(gap: Spacing, font: LabelPaperTypography, label: Pick<LabelPaper, 'labelMargins' | 'labelPaddings'>) => (
				<div
					className="paper__label-grid"
					style={{
						gridTemplateColumns: `repeat(${columns}, 1fr)`,
						gridTemplateRows: `repeat(${rows}, 1fr)`,
						columnGap: `${gap.column}px`,
						rowGap: `${gap.row}px`,
					}}
					onMouseLeave={() => setMouseFilled(null)}
				>
					{
						elements.map((item: string, index: number) => (
							<div
								title={item}
								key={`${index * 2}-${item}`}
								onClick={() => props.onChange?.(index)}
								onMouseEnter={() => isPresent(props.offset) && setMouseFilled(index)}
								className={clsx(
									'paper__label',
									isPresent(props.offset)
									&& showOffset(index, mouseFilled, props.offset)
									&& 'paper__label__filled',
								)}
								style={{
									borderTopWidth: label.labelMargins.top,
									borderRightWidth: label.labelMargins.right,
									borderBottomWidth: label.labelMargins.bottom,
									borderLeftWidth: label.labelMargins.left,
									padding: `${label.labelPaddings.top}px ${label.labelPaddings.right}px ${label.labelPaddings.bottom}px ${label.labelPaddings.left}px`,
								}}
							>
								<LabelSkeleton font={font} />
							</div>
						))
					}
				</div>
			)}
		</ContainerPreview>
	);
};
