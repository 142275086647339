import * as React from 'react';

import clsx from 'clsx';
import Alert from 'antd/lib/alert';

import { Margins, Size } from '@app/objects/Geometry';
import {
	LabelPaper,
	LabelPaperTypography,
	Spacing,
} from '@app/objects/LabelPaper';
import {
	DimensionLabel,
} from '@app/components/Pages/Admin/LabelPaper/LabelPaperEditor/LabelPaperEditorComponents/PreviewComponents/DimensionLabel';
import { PaperManager } from '@app/components/Pages/Admin/LabelPaper/LabelPaperEditor/LabelPaperEditorComponents/PreviewComponents/PaperManager';
import { useTrackSize } from '@app/hooks/useTrackSize';
import { unitName } from '@app/components/Pages/Admin/LabelPaper/LabelPaperList';
import { Stack } from '@app/components/Layout/Stack';

import '@app/scss/ui/paper.scss';

interface ContainerPreviewProps {
	paper: LabelPaper;

	className?: string;
	style?: React.CSSProperties;

	children: (gap: Spacing, font: LabelPaperTypography, label: Pick<LabelPaper, 'labelMargins' | 'labelPaddings'>) => React.ReactNode;
}

interface LayoutEnvelope {
	margins: Margins;
	size: Size;
	spacing: Spacing;
	font: LabelPaperTypography;
	label: Pick<LabelPaper, 'labelMargins' | 'labelPaddings'>;
}

function useLayout(paper: LabelPaper, limit: Size): LayoutEnvelope {
	const {
		width,
		height,
		margins,
		spacing,
		typography,
		labelMargins,
		labelPaddings,
	} = paper;
	const size: Size = React.useMemo(() => ({
		width,
		height,
	}), [width, height]);
	const rect: Size = React.useMemo<Size>(() => {
		const ratio = size.width / size.height;
		if (ratio > 1) {
			const width = limit.width;
			const height = width / ratio;

			return {
				width,
				height,
			};
		}

		const height = limit.height;
		const width = height * ratio;

		return {
			width,
			height,
		};
	}, [
		size.width,
		size.height,
	]);

	const absolute: Size = React.useMemo(() => ({
		height: rect.height,
		width: rect.width,
	}), [rect]);

	const { xRatio, yRatio } = React.useMemo(() => {
		const xRatio = absolute.width / size.width;
		const yRatio = absolute.height / size.height;

		return { xRatio, yRatio };
	}, [absolute, size]);

	const result: Margins = React.useMemo<Margins>(() => ({
		left: margins.left * xRatio,
		right: margins.right * xRatio,
		top: margins.top * yRatio,
		bottom: margins.bottom * yRatio,
	}), [
		xRatio,
		yRatio,
		margins,
	]);
	const gap: Spacing = React.useMemo<Spacing>(() => ({
		column: spacing.column * yRatio,
		row: spacing.row * xRatio,
	}), [
		xRatio,
		yRatio,
		spacing,
	]);
	const fontSize: LabelPaperTypography = React.useMemo(() => {
		const ratio = 0.03 * yRatio;

		return {
			titleFontSize: typography.titleFontSize * ratio,
			headerFontSize: typography.headerFontSize * ratio,
			baseFontSize: typography.baseFontSize * ratio,
		};
	}, [typography, yRatio]);
	const label: Pick<LabelPaper, 'labelMargins' | 'labelPaddings'> = React.useMemo(() => ({
		labelMargins: {
			left: labelMargins.left * xRatio,
			right: labelMargins.right * xRatio,
			top: labelMargins.top * yRatio,
			bottom: labelMargins.bottom * yRatio,
		},
		labelPaddings: {
			left: labelPaddings.left * xRatio,
			right: labelPaddings.right * xRatio,
			top: labelPaddings.top * yRatio,
			bottom: labelPaddings.bottom * yRatio,
		},
	}), [labelMargins, labelPaddings, xRatio, yRatio]);

	return {
		margins: result,
		size: rect,
		spacing: gap,
		font: fontSize,
		label,
	};
}

function clamp(min: number, value: number, max: number): number {
	return Math.min(Math.max(min, value), max);
}

export const ContainerPreview: React.FC<ContainerPreviewProps> = (props: ContainerPreviewProps) => {
	const { ref, width, height } = useTrackSize();
	const limit: Size = {
		width: clamp(500, width, 700),
		height: clamp(500, height, 700),
	};
	const {
		margins, size, spacing, font, label,
	} = useLayout(props.paper, limit);
	const {
		labelWidth,
		labelHeight,
		labelSize,
	} = React.useMemo(() => {
		const unit = unitName(props.paper.unit);
		const manager = PaperManager.for(props.paper);

		return {
			labelWidth: manager.labelWidth,
			labelHeight: manager.labelHeight,
			labelSize: `${manager.labelWidth.toFixed(2)} ${unit} x ${manager.labelHeight.toFixed(2)} ${unit}`,
		};
	}, [props.paper]);

	return (
		<>
			<Stack>
				<div>
					<div>The size of Label:</div>
					<div style={{ opacity: 0.8 }}>{labelSize}</div>
				</div>
				{
					(labelWidth < 0 || labelHeight < 0)
						? (
							<Alert
								message="Labels do not fit into paper!"
								type="warning"
								showIcon
								closable
								style={{ height: 'fit-content' }}
							/>
						) : null
				}
			</Stack>

			<div
				className={clsx('paper-container', props.className)}
				style={props.style}
				ref={ref}
			>
				<div
					className="paper"
					style={{
						padding: `${margins.top}px ${margins.right}px ${margins.bottom}px ${margins.left}px`,
						width: `${size.width}px`,
						height: `${size.height}px`,
					}}
				>
					<div className="paper__width-line">
						<DimensionLabel
							value={props.paper.width}
							unit={props.paper.unit}
							title="Width"
						>
							W
						</DimensionLabel>
					</div>
					<div className="paper__height-line">
						<DimensionLabel
							value={props.paper.height}
							unit={props.paper.unit}
							title="Height"
						>
							H
						</DimensionLabel>
					</div>

					<div className="paper__margin-line paper__margin-line_top" style={{ top: `${margins.top}px` }}>
						<DimensionLabel
							value={props.paper.margins.top}
							unit={props.paper.unit}
							title="Margin Top"
						>
							MT
						</DimensionLabel>
					</div>
					<div className="paper__margin-line paper__margin-line_left" style={{ left: `${margins.left}px` }}>
						<DimensionLabel
							value={props.paper.margins.left}
							unit={props.paper.unit}
							title="Margin Left"
						>
							ML
						</DimensionLabel>
					</div>

					{props.children(spacing, font, label)}

					<div className="paper__margin-line paper__margin-line_right" style={{ right: `${margins.right}px` }}>
						<DimensionLabel
							value={props.paper.margins.right}
							unit={props.paper.unit}
							title="Margin Right"
						>
							MR
						</DimensionLabel>
					</div>
					<div className="paper__margin-line paper__margin-line_bottom" style={{ bottom: `${margins.bottom}px` }}>
						<DimensionLabel
							value={props.paper.margins.bottom}
							unit={props.paper.unit}
							title="Margin Bottom"
						>
							MB
						</DimensionLabel>
					</div>
				</div>
			</div>
		</>
	);
};
