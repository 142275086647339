import * as React from 'react';

import modal from 'antd/lib/modal';

import Button from '@common/react/components/Forms/Button';

import { request } from '@app/components/Api';
import { alertMessage, MessageType } from '@app/utilities/alert';

interface DeactivateDropdownButtonProps {
	userId: number;
	disabled?: boolean;
	onDeactivated?: () => void;
}

export const DeactivateDropdownButton = (props: DeactivateDropdownButtonProps): JSX.Element => {
	const [isLoading, setLoading] = React.useState<boolean>(false);

	const deactivate = () => {
		modal.confirm({
			title: 'Delete user',
			content: `Are you sure you want to delete user #${props.userId}?
			This user won't be able to log in again and you won't be able to make it active again.`,
			onOk: () => {
				setLoading(true);

				return request('deleteUser', { id: props.userId })
					.then(() => {
						alertMessage(MessageType.success, `User #${props.userId} has been deleted.`);
						props.onDeactivated?.();
					})
					.catch((err: string) => { alertMessage(MessageType.error, `Unable to delete user #${props.userId}: ${err}`); })
					.finally(() => setLoading(false));
			},
		});
	};

	return (
		<Button
			className="btn btn-transparent color-red"
			title="Delete"
			onClick={deactivate}
			isLoading={isLoading}
			disabled={props.disabled}
		>
			<i className="fa fa-times" />
			<span className="icon-margin">Delete</span>
		</Button>
	);
};
