import { FormikProps } from 'formik';

import { Nullable } from '@common/typescript/objects/Nullable';
import {
	getShallowChanges,
	pickDifference,
} from '@common/typescript/utils/entity';

import { ServiceType, PetPrice } from '@app/objects/Pet';
import { ViewMode } from '@app/components/Utils/Utils';
import { Clinic } from '@app/objects/Clinic';
import { PriceKind } from '@app/objects/Price';
import {
	CreateMessage,
	Delivery,
	FormValues,
	PetPriceMessage,
	UpdateMessage,
} from '@app/components/Pages/ClinicPetEditor/types';

function toPetPriceMessage(item: PetPrice): PetPriceMessage {
	let priceKind = item.price?.priceKind;

	if (priceKind !== PriceKind.SpecialServicePrice && priceKind !== PriceKind.ProductPrice) {
		priceKind = undefined;
	}

	return {
		id: item.id,
		removed: item.removed,

		petId: item.id,
		priceId: item.priceId,
		priceKind,

		count: item.count,
		completedCount: item.completedCount,
		done: item.done,

		value: item.value,
		extra: item.extra,
		name: item.name,

		batchCount: item.batchCount,
		batchPrice: item.batchPrice,

		pickupService: item.pickupService,
		pickupServiceId: item.pickupServiceId,

		node: null,
		nodeId: item.nodeId,

		note: item.note,
	};
}

function getDelivery(values: FormValues): Partial<Delivery> {
	return {
		deliveryType: values.deliveryType,
		deliveryAddress: values.deliveryAddress,
		deliveryAddress2: values.deliveryAddress2,
		deliveryCity: values.deliveryCity,
		deliveryZip: values.deliveryZip,
		deliveryStateId: values.deliveryStateId,
		deliveryClinicId: values.deliveryClinicId,
	};
}

export function toCreateMessage(values: FormValues): CreateMessage {
	const services = [...values.services, ...values.products, ...values.urns].filter((item: PetPrice) => !item.removed).map(toPetPriceMessage);

	return {
		name: values.name,
		engraving: values.engraving ?? [],
		color: values.color,
		reportedWeight: values.reportedWeight,

		discountId: values.discountId,
		genderId: values.genderId ?? -1,
		petBreedId: values.petBreedId,
		petSpecieId: values.petSpecieId,
		isMixed: values.isMixed,
		clinicLocationId: values.clinicLocationId,

		...getDelivery(values),

		ownerEmail: values.ownerEmail,
		ownerFirstName: values.ownerFirstName,
		ownerLastName: values.ownerLastName,
		ownerPhone: values.ownerPhone,

		services,
		serviceType: values.serviceType,
		specialInstructions: values.specialInstructions,

		noAshes: values.noAshes,
		onHold: values.onHold,
		rush: values.rush,
		internalIdNum: values.internalIdNum,
		files: values.files,
	};
}

function convertToUpdateMessage(values: FormValues): UpdateMessage {
	const services = [
		...values.services,
		...values.products,
		...values.urns,
	].map(toPetPriceMessage);

	return {
		id: values.id,

		name: values.name,
		engraving: values.engraving ?? [],
		color: values.color,
		reportedWeight: values.reportedWeight,

		discountId: values.discountId,
		genderId: values.genderId ?? -1,
		petBreedId: values.petBreedId,
		petSpecieId: values.petSpecieId,
		isMixed: values.isMixed,
		clinicLocationId: values.clinicLocationId,

		...getDelivery(values),

		ownerEmail: values.ownerEmail,
		ownerFirstName: values.ownerFirstName,
		ownerLastName: values.ownerLastName,
		ownerPhone: values.ownerPhone,

		specialInstructions: values.specialInstructions,
		serviceType: values.serviceType,
		services,

		noAshes: values.noAshes,
		onHold: values.onHold,
		rush: values.rush,
		internalIdNum: values.internalIdNum,
		files: values.files,
	};
}

export function toUpdateMessage(values: FormValues, initial: FormValues): Partial<UpdateMessage> {
	const data = getShallowChanges(convertToUpdateMessage(initial), convertToUpdateMessage(values));

	const newServices = (data.services ?? []).filter((q: PetPriceMessage) => q.id === -1);
	const updatedServices = (data.services ?? []).filter((q: PetPriceMessage) => q.id > 0)
		.filter((q1: PetPriceMessage) => {
			const initialServices = [...initial.services, ...initial.products, ...initial.urns];
			const value = initialServices.find((q2: PetPrice) => q2.id === q1.id);

			if (value === undefined) return false;

			return value.removed !== q1.removed
				|| value.count !== q1.count
				|| value.value !== q1.value
				|| value.priceId !== q1.priceId
				|| value.nodeId !== q1.nodeId;
		});

	data.services = [...newServices, ...updatedServices];

	return {
		...data,
		withCrematory: true,
		withSpecie: true,
		withBreed: true,
		withGender: true,
		withServices: true,
		withUrns: true,
	};
}

const formatServices = (array: Array<PetPrice>) => {
	const filteredArray = array.filter((i) => i.price?.priceKind === PriceKind.SpecialServicePrice || i.price?.priceKind === PriceKind.UrnPrice);

	if (filteredArray) {
		return filteredArray.map((item: PetPrice) => {
			return {
				completedCount: item.completedCount,
				count: item.count,
				done: item.done,
				extra: item.extra,
				id: item.id,
				name: item.name,
				price: null,
				priceId: item.priceId,
				value: item.value,
				removed: item.removed ?? false,
			};
		});
	}

	return null;
};

export function servicesDiff(initial: Array<PetPrice>, update: Array<PetPrice>) {
	const initialValues = formatServices(initial);
	const updateValues = formatServices(update);

	if (!initialValues || !updateValues) return false;

	return pickDifference(initialValues, updateValues).length;
}

export function setDefaultDeliveryType(props: FormikProps<FormValues>, clinic: Nullable<Clinic>) {
	let deliveryType;

	switch (props.values.serviceType) {
	case ServiceType.Private:
		deliveryType = clinic?.defaultPrivateDeliveryType;
		break;
	case ServiceType.SemiPrivate:
		deliveryType = clinic?.defaultSemiPrivateDeliveryType;
		break;
	case ServiceType.Communal:
		deliveryType = clinic?.defaultCommunalDeliveryType;
		break;
	default:
		deliveryType = clinic?.defaultCommunalDeliveryType;
	}

	props.setFieldValue('deliveryType', deliveryType);
}

export function getMode(type: string, isEditable: boolean): ViewMode {
	return type === 'edit' && isEditable ? ViewMode.edit : ViewMode.view;
}
