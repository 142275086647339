import React from 'react';

import { Select } from '@app/components/UI/Select/Select';
import { Option } from '@app/components/UI/Select/Option';
import { dateFormatOptions } from '@app/components/UI/Inputs/LocalSelectOptions';
import { Option as IOption } from '@app/components/UI/Inputs/LocalSelect';

interface DateFormatSelectProps {
	value: string;
	onChange: (value: string) => void;
}

export const DateFormatSelect: React.FC<DateFormatSelectProps> = ({ value, onChange }: DateFormatSelectProps) => (
	<Select
		value={value}
		onChange={(value: string) => onChange(value)}
		placeholder="Select the date format"
		showClear={false}
	>
		{
			dateFormatOptions.map((item: IOption) => (
				<Option key={item.value} value={item.value}>
					{item.text}
				</Option>
			))
		}
	</Select>
);
