import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'antd/lib/button';

import { WithId } from '@common/typescript/objects/WithId';
import { TableRemoteSorterData } from '@common/react/smart components/Table/TableRemoteSorter';

import { Filters } from '@app/components/UI/Filters/FilterHook';
import { getFilters, onChangeValue } from '@app/components/UI/Filters/FiltersList';
import '@app/scss/ui/filters-component.scss';
import { FilterIcon, MobileFiltersBar } from '@app/components/UI/Filters/MobileFilters';
import { TotalQuantity } from '@app/components/UI/Filters/TotalQuantity';

const SHOWN_FILTERS_AMOUNT = 3;

interface OwnProps<T extends WithId> {
	filters: Partial<Filters>;
	onChange: (name: string, value: onChangeValue) => void;
	setQueryParams: (i: Partial<Filters>) => void;
	clearQueryParams: () => void;
	onDeselect: (fieldName: string, type: string, id?: number) => void;
	filterNames: Array<string>;
	sorterNames?: Array<string>;
	isLoading: boolean;
	totalQuantity?: number;
	sorter?: TableRemoteSorterData<T>|undefined;
	defaultParams?: Record<string, unknown>;
	customProperties?: Partial<Record<keyof Filters, Record<string, unknown>>>;
	crematoryId?: number;
}

export const FiltersComponent = <T extends WithId>(props: OwnProps<T>):JSX.Element => {
	const {
		filters, onChange, setQueryParams, clearQueryParams,
		onDeselect, filterNames, isLoading, totalQuantity,
		sorter, sorterNames = filterNames, defaultParams, crematoryId, customProperties,
	} = props;
	const { t } = useTranslation('translation', { keyPrefix: 'filters' });
	const handleSubmit = () => setQueryParams(filters);

	const [opened, setOpened] = React.useState<boolean>(false);

	const allFilterFields = getFilters(filters, onChange, onDeselect, handleSubmit, defaultParams, crematoryId, customProperties);

	const filtersQuantity = Object.keys(filters).length;

	return (
		<>
			<div className="filters-outer-wrap">
				<div className="filters-inner-wrap">
					<div className="search-fields-block">
						<div className="search-fields-block__shown">
							{filterNames.slice(0, SHOWN_FILTERS_AMOUNT).map((elm) => (
								<div
									className="filter-element filter-element--shown"
									key={elm}
								>
									{allFilterFields[elm]}
								</div>
							))}
						</div>
						{filterNames.length > SHOWN_FILTERS_AMOUNT && (
							<div className="filter-btn-container">
								<Button
									onClick={() => setOpened(!opened)}
									className={`filter-btn${opened ? '--opened' : ''}`}
									style={{ width: '100%' }}
								>
									{opened ? <i className="fa fa-close" /> : <FilterIcon /> }
								</Button>
							</div>
						)}
					</div>
					<div className="filter-buttons-block">
						<Button
							type="link"
							onClick={() => clearQueryParams()}
							className="btn btn-transparent btn-reset"
							disabled={!Object.keys(filters).length}
						>
							<span>{t('reset')}</span>
						</Button>

						<Button
							type="primary"
							onClick={handleSubmit}
							className="btn btn--primary"
							loading={isLoading}
						>
							<span>{t('search')}</span>
							{filtersQuantity > 0 && <span className="applied-filters-amount">{filtersQuantity}</span>}
						</Button>
					</div>
				</div>
				<div className="search-fields-block__hidden">
					{opened && filterNames.slice(SHOWN_FILTERS_AMOUNT).map((elm) => (
						<div
							className="filter-element filter-element--hidden"
							key={elm}
						>
							{allFilterFields[elm]}
						</div>
					))}
				</div>
			</div>
			<TotalQuantity count={totalQuantity} withTotalQuantity />

			<MobileFiltersBar
				clearQueryParams={clearQueryParams}
				filterNames={filterNames}
				sorterNames={sorterNames}
				filters={filters}
				onChange={onChange}
				onDeselect={onDeselect}
				setQueryParams={setQueryParams}
				sorter={sorter}
			/>
		</>
	);
};
