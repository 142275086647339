import { Nullable } from '@common/typescript/objects/Nullable';

import { PetPrice, ServiceType } from '@app/objects/Pet';
import { Price, PriceKind, PriceType } from '@app/objects/Price';
import { getPriceValue } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/SpecialServiceList';

interface FiltersParams {
	priceKind: Array<PriceKind>;
}

export interface PriceListParams {
	clinicId: Nullable<number>;
	priceType: PriceType;
	serviceType: ServiceType;
	withInventoryItem: boolean;
	count: number;
	filters: FiltersParams;
}

export function getPetPrice(items: Array<PetPrice>) {
	return items.map((elm: PetPrice) => ({
		id: elm.id,
		title: elm.name,
		data: elm.count.toString(),
	}));
}

/**
 * Calculate over batch price
 * @param {Array<PetPrice>} - a list of requested services or urns for current pet
 * @returns {number} - total additional price
 */
export function getAdditionalPrice(array: Array<PetPrice> = []): number {
	return array.reduce((acc: number, elm: PetPrice) => {
		let price = elm.price;
		if (!price) price = { batchCount: elm.batchCount, batchPrice: elm.batchPrice, value: elm.value } as Price;

		const value = getPriceValue(price, elm.count).value;

		return value + acc;
	}, 0);
}

/**
 * Get disable 'Add'-btn for services & products modal
 * @param {ServiceType} - value of serviceType
 * @returns {boolean}
 */
export const getDisableModal = (serviceType?: ServiceType): boolean => (serviceType === undefined || serviceType < 0);
