import * as React from 'react';

interface TrackSizeEnvelope {
	ref: React.RefObject<HTMLDivElement>;
	width: number;
	height: number;
}

export function useTrackSize(): TrackSizeEnvelope {
	/* Disable for server render */
	const ref = React.useRef<HTMLDivElement>(null);
	const [width, setWidth] = React.useState<number>(0);
	const [height, setHeight] = React.useState<number>(0);

	React.useEffect(() => {
		// eslint-disable-next-line compat/compat
		const observer = new ResizeObserver(([item]: Array<ResizeObserverEntry>) => {
			if (!item) return;

			setWidth(item.contentRect.width);
			setHeight(item.contentRect.height);
		});

		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => observer.disconnect();
	}, []);

	return {
		ref,
		width,
		height,
	};
}
