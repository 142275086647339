import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Field, FieldProps } from 'formik';
import { SelectValue } from 'antd/lib/select';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { Nullable } from '@common/typescript/objects/Nullable';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { ApplicationState } from '@app/store';
import { bindItemsActionCreators } from '@app/store/ItemList';
import {
	DeliveryType,
	PetOnHold,
} from '@app/objects/Pet';
import { Location } from '@app/objects/Location';

import { LocalSelect } from '@app/components/UI/Inputs/LocalSelect';
import { LocationSelect } from '@app/components/UI/Inputs/LocationSelect';
import { CardSize, getCardFields, Card } from '@app/components/UI/Cards/Card';
import { DeliveryTypeSelect } from '@app/components/UI/Inputs/DeliveryTypeSelect';
import { FormValues } from '@app/components/Pages/ClinicPetEditor/types';
import { BaseSectionProps, getCremationInfo } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/ViewContent';
import { serviceTypes } from '@app/components/UI/Inputs/LocalSelectOptions';
import { ClinicSelect } from '@app/components/UI/Inputs/ClinicSelect';
import { ClinicSelectInfo } from '@app/objects/Clinic';
import { isPickDefault } from '@app/store/SelectList/SelectsInterfaces';

function preselect(items: Array<Location>): number | undefined {
	if (items.length === 1) return items[0].id;

	return undefined;
}

export const CremationDetails: React.FC<Omit<BaseSectionProps, 'crematoryId'>> = ({ crematory, mode }: Omit<BaseSectionProps, 'crematoryId'>) => {
	const { t } = useTranslation();
	const clinic = useSelector((state: ApplicationState) => state.clinic.item);
	const clinicLocations = useSelector((state: ApplicationState) => state.clinicLocations.items);

	const dispatch = useDispatch();
	const factory = React.useMemo(() => bindItemsActionCreators<Location>(dispatch), [dispatch]);

	React.useEffect(() => {
		factory.reqPages('clinicLocations', 'locationList', 'location', {
			objectId: clinic?.id,
			getMine: true,
			count: 20,
			offset: 0,
		});
	}, [factory, clinic?.id]);

	return (
		<Card
			name="Cremation information"
			size={CardSize.Medium}
			mode={mode}
			fullWidth

			editContent={(
				<div>
					<div className="row">
						<Field name="serviceType">
							{(fieldProps: FieldProps<FormValues>) => (
								<FormikInput
									fieldProps={fieldProps}
									title="Cremation Type*"
									containerClassName="col-sm-12 col-md-6 form-group"
									render={({ field, form }: FieldProps<SelectValue, FormValues>) => (
										<LocalSelect
											onChange={(e: SelectValue) => {
												form.setFieldValue(field.name as keyof FormValues, e, false);
												form.setFieldValue('services', [], false);
												form.setFieldValue('urns', [], false);
											}}
											value={typeof field.value === 'number' && field.value >= 0 ? field.value : undefined}
											options={serviceTypes(crematory)}
											fieldName={field.name}
											deselectType={field.name}
											filterName={field.name}
											allowClear={false}
											placeholder="Select cremation type"
										/>
									)}
								/>
							)}
						</Field>
						<Field name="clinicLocationId">
							{(fieldProps: FieldProps<FormValues>) => (
								<FormikInput
									fieldProps={fieldProps}
									title="Clinic location"
									inputProps={{
										disabled: fieldProps.form.values.deliveryType === DeliveryType.Clinic && fieldProps.form.values.deliveryClinicId,
									}}
									containerClassName="col-sm-12 col-md-6 form-group "
									render={({ field, form }: FieldProps<Nullable<number>, FormValues>) => (
										<LocationSelect
											value={field.value ?? undefined}
											onChange={(value: Nullable<number>) => form.setFieldValue('clinicLocationId', value, false)}

											preselect={field.value ? undefined : preselect}
											disabled={(items: Array<Location>) => items.length === 0}

											forMyClinic
										/>
									)}
								/>
							)}
						</Field>
						<Field name="deliveryType">
							{(fieldProps: FieldProps<FormValues>) => {
								if (!clinic?.canSetDeliveryType) return null;

								return (
									<FormikInput
										fieldProps={fieldProps}
										containerClassName="col-sm-12 col-md-6 form-group"
										title="Delivery Type"
										render={({ field, form }: FieldProps<number, FormValues>) => (
											<DeliveryTypeSelect
												value={field.value}
												onChange={(value) => {
													form.setValues({
														...form.values,
														deliveryType: value,
														deliveryAddress: '',
														deliveryAddress2: '',
														deliveryZip: '',
														deliveryCity: '',
														deliveryStateId: null,
														deliveryState: null,
													});

													if (value !== DeliveryType.Clinic) form.setFieldValue('deliveryClinicId', form.initialValues.deliveryClinicId);
												}}
											/>
										)}
									/>
								);
							}}
						</Field>
						<Field name="deliveryClinicId">
							{(fieldProps: FieldProps<FormValues>) => {
								if (!clinic?.canSetDeliveryType || fieldProps.form.values.deliveryType !== DeliveryType.Clinic) return null;

								return (
									<FormikInput
										fieldProps={fieldProps}
										containerClassName="col-sm-12 col-md-6 form-group"
										title="Custom Delivery Clinic"
										render={({ field, form }: FieldProps<number, FormValues>) => (
											<ClinicSelect
												value={field.value}
												crematoryId={crematory?.id}
												onChange={(id?: number) => form.setFieldValue(field.name, id, false)}
												pickDefault={(store: Array<ClinicSelectInfo>) => isPickDefault(store)}
												placeholder="Select the Custom Delivery Clinic"
											/>
										)}
									/>
								);
							}}
						</Field>
					</div>

					<div className="row">
						<Field name="onHold">
							{(fieldProps: FieldProps<FormValues>) => {
								if (!clinic?.canPutOnHold) return null;

								return (
									<FormikInput
										fieldProps={fieldProps}
										title="On hold"
										containerClassName="col-sm-12 col-md-6 form-group"
										render={({ field, form }: FieldProps<number, FormValues>) => (
											<Checkbox
												checked={field.value === PetOnHold.ByClinic}
												onChange={(value: CheckboxChangeEvent) => {
													const isOnHold = value.target.checked ? PetOnHold.ByClinic : PetOnHold.None;
													form.setFieldValue(field.name as keyof FormValues, isOnHold, false);
												}}
											>
												Put pet on hold
											</Checkbox>
										)}
									/>
								);
							}}
						</Field>
						<Field name="rush">
							{(fieldProps: FieldProps<FormValues>) => {
								if (!clinic?.canPutOnRush) return null;

								return (
									<FormikInput
										fieldProps={fieldProps}
										title="Rush"
										containerClassName="col-sm-12 col-md-6 form-group"
										render={({ field, form }: FieldProps<boolean, FormValues>) => (
											<Checkbox
												checked={field.value}
												onChange={(value: CheckboxChangeEvent) =>
													form.setFieldValue(field.name as keyof FormValues, value.target.checked, false)}
											>
												Pet processing should be urgent
											</Checkbox>
										)}
									/>
								);
							}}
						</Field>
					</div>
				</div>
			)}

			viewContent={(
				<Field name="">
					{({ form }: FieldProps<FormValues>) =>
						getCardFields(getCremationInfo(form.values, t, clinic, clinicLocations))}
				</Field>
			)}
		/>
	);
};
