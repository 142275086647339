import React from 'react';
import clsx from 'clsx';
import CoreTable, { ColumnProps, ExpandIconProps, TableEventListeners } from 'antd/lib/table';

import { WithId } from '@common/typescript/objects/WithId';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { TableRemoteSorterData } from '@common/react/smart components/Table/TableRemoteSorter';

import { ActionFactory } from '@app/components/Utils/ActionFactory';
import { ActionButtons } from '@app/components/UI/Buttons/ActionButtons';
import { RemoteDataEnvelope } from '@app/hooks/RemoteDataProvider/RemoteDataProvider';

interface TableProps<T extends WithId, TParams> {
	data: RemoteDataEnvelope<T, TParams>;
	columns: Array<ColumnProps<T>>;

	sorter?: TableRemoteSorterData<T>;
	actions?: ActionFactory<T>;
	expandedRow?: {
		render: (record: T, index: number, indent: number, expanded: boolean) => React.ReactNode;
		expandable: (record: T) => React.ReactNode;
	};

	className?: string;
	style?: React.CSSProperties;

	rowKey?: (record: T) => string | string;
	onRow?: (record: T, index: number) => TableEventListeners;
}

function getActionsColumn<T extends WithId>(
	reload: () => void,
	actions?: ActionFactory<T>,
): Array<ColumnProps<T>> {
	if (actions === undefined) return [];

	return TableBuilder.shape<T>()
		.addColumn({
			title: 'Actions',
			width: '100px',
			align: 'center',
			render: (text, record) => (
				<ActionButtons
					actions={actions({ reload, record })}
				/>
			),
		}).build();
}

export const Table = <T extends WithId, TParams = Record<string, unknown>>(props: TableProps<T, TParams>) => {
	const {
		data,
		columns,
		actions,
		sorter,
		rowKey,
		expandedRow,
		onRow,
		className,
		style,
	} = props;

	return (
		<CoreTable
			columns={[
				...columns,
				...getActionsColumn<T>(
					data.fetch,
					actions,
				),
			]}
			dataSource={data.items}
			pagination={{
				...data.pagination,
				current: data.pagination.page + 1,
				total: data.pagination.total,
				pageSize: data.pagination?.perPage,
				onChange: (page: number) => data.onPageChange(page - 1),
				hideOnSinglePage: true,
			}}
			loading={data.loading}
			onChange={((pagination, filters, sorterResult) => {
				sorter?.setSorter(sorterResult);
				const page = (pagination.current ?? 1) - 1;
				data.onPageChange(page);
			})}
			childrenColumnName="child"
			rowKey={rowKey}
			style={style}
			className={clsx('table-mobile', className)}
			expandIcon={
				(props: ExpandIconProps<T>) =>
					(expandedRow?.expandable(props.record)
						? (
							<div
								role="button"
								tabIndex={0}
								className={`ant-table-row-expand-icon ant-table-row-${props.expanded ? 'expanded' : 'collapsed'}`}
								aria-label="Expand row"
								onClick={(event: React.MouseEvent<HTMLDivElement>) => props.onExpand(props.record, event)}
							/>
						)
						: null)
			}
			expandedRowRender={expandedRow?.render}
			onRow={onRow}
		/>
	);
};

Table.defaultProps = {
	rowKey: 'id',
};
