import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'antd/lib/tooltip';
import Button from 'antd/lib/button';

import { useModal } from '@app/hooks/Editors/useModal';
import { CopyModal } from '@app/components/Utils/CopyRequest';
import { BackButton } from '@app/components/UI/Header/PageHeaders/BackButton';

export interface ListHeaderProps {
	name?: React.ReactNode;
	showAddButton?: boolean;
	copyButton?: boolean;
	extraBtn?: React.ReactNode;
	extraContent?: React.ReactNode;
	itemName?: string;
	handleAddNewClick?: () => void;
	addButtonProps?: Record<string, unknown>;
	withHeaderMargin?: boolean;
	customAddButton?: JSX.Element;
	styles?: React.CSSProperties;

	reload?: () => void;
	reqParams?: Record<string, unknown>;
	copyEndpoint?: string;
	successMessage?: string;
	errorMessage?: string;
	title?: string;
	content?: (modalState: boolean) => React.ReactNode;
	buttonCopyName?: string;
	copyModalWidth?: string;
	okButtonProps?: Record<string, unknown>;
	backPath?: string;
}

type AddButtonProps = Pick<ListHeaderProps, 'customAddButton' | 'handleAddNewClick' | 'itemName' | 'addButtonProps'>

const getPageTitle = (name: React.ReactNode) => {
	if (typeof name === 'string') return <h1 className="header">{name}</h1>;

	return name;
};

export const AddButton: React.FC<AddButtonProps> = ({
	itemName, customAddButton, handleAddNewClick, addButtonProps,
}: AddButtonProps) => {
	const { t } = useTranslation();
	const buttonAddName = `${t('buttons.add')} ${itemName}`;

	if (customAddButton) return customAddButton;

	return (
		<>
			<span className="btn--add-with-icon">
				<Tooltip title={buttonAddName}>
					<Button
						type="primary"
						icon="plus"
						size="default"
						onClick={handleAddNewClick}
						className="btn btn-primary"
						{...addButtonProps}
					/>
					<span className="visually-hidden">{buttonAddName}</span>
				</Tooltip>
			</span>
			<span className="btn--add-with-word">
				<Button
					type="primary"
					onClick={handleAddNewClick}
					className="btn btn-primary"
					{...addButtonProps}
				>
					<span>{buttonAddName}</span>
				</Button>
			</span>
		</>
	);
};

export const ListHeader: React.FC<ListHeaderProps> = ({
	name,
	showAddButton = true,
	copyButton,
	extraBtn,
	extraContent,
	itemName,
	handleAddNewClick,
	withHeaderMargin = false,
	reload,
	reqParams,
	copyEndpoint,
	successMessage,
	errorMessage,
	title,
	content,
	buttonCopyName,
	copyModalWidth,
	okButtonProps,
	backPath,
	customAddButton,
	addButtonProps,
	styles,
}): JSX.Element => {
	const header = name ? getPageTitle(name) : null;
	const modal = useModal(false);

	return 	(
		<div style={withHeaderMargin ? { marginTop: '30px' } : undefined}>
			{backPath && <BackButton backPath={backPath} />}
			<div
				className="site-header__list-wrap"
				style={styles}
			>
				{header}

				<div style={{ display: 'flex', gap: '1em' }}>
					<div>
						{copyButton && content && (
							<>
								<span className="btn--add-with-word">
									<Button
										type="primary"
										className="btn btn-primary btn-outline pull-right"
										onClick={modal.open}
									>
										<span>{buttonCopyName}</span>
									</Button>
								</span>
								<span className="btn--add-with-icon">
									<Tooltip title={buttonCopyName}>
										<Button
											type="primary"
											icon="copy"
											size="small"
											onClick={modal.open}
											className="btn btn-primary pull-right"
										/>
										<span className="visually-hidden">{buttonCopyName}</span>
									</Tooltip>
								</span>
							</>
						)}
					</div>
					<div>
						{extraBtn}
					</div>
					{showAddButton && <AddButton itemName={itemName} handleAddNewClick={handleAddNewClick} customAddButton={customAddButton} addButtonProps={addButtonProps} />}
				</div>

				{extraContent}
			</div>
			{content && (
				<CopyModal
					modal={modal}
					modalWidth={copyModalWidth}
					copyEndpoint={copyEndpoint}
					errorMessage={errorMessage}
					successMessage={successMessage}
					title={title}
					content={() => content?.(modal.state)}
					reqParams={reqParams}
					reload={reload}
					okButtonProps={okButtonProps}
				/>
			)}
		</div>
	);
};
