import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';

import { MobileCell } from '@common/react/components/Pages/ItemsPage';
import { WithId } from '@common/typescript/objects/WithId';
import Button from '@common/react/components/Forms/Button';
import { Pagination } from '@common/react/smart components/Table/TableDataProvider';

import { handleDelete } from '@app/components/UI/Table/services';
import { IActions } from '@app/components/Utils/ActionFactory';
import { request } from '@app/components/Api';
import { alertMessage, MessageType } from '@app/utilities/alert';
import { ResourceButton } from '@app/components/UI/Buttons/ResourceButton';

interface OwnProps {
	itemName?: string;
	actions: Array<IActions>;
}

interface DeleteRemoteProps {
	itemName: string;
	deleteEndpoint: string|undefined;
	requestParams: Record<string, unknown>|undefined;
	reload: () => void;
	pagination?: Pagination;
	id: number;
	errorSettings?: (error: string) => void;
}

interface RequestButtonProps {
	itemName: string;
	updateEndpoint: string;
	requestParams?: Record<string, unknown>;
	reload: () => void;
	disabled?: boolean;
	message?: string;
	icon?: React.ReactNode,
}

interface EditButtonProps {
	onClick?: () => void;
	linkPath?: string;
}

interface DeleteWithReduxProps<T> {
	deleteItem: (e: React.MouseEvent<HTMLButtonElement>, item: T) => void;
	record: T;
}

interface PrintButtonProps {
	id: number;
}

const localKey = 'table.actions';

export const DeleteWithRedux = <T extends WithId>(props: DeleteWithReduxProps<T>): JSX.Element => {
	const { t } = useTranslation('translation', { keyPrefix: localKey });
	const { deleteItem, record } = props;

	return (
		<Button
			className="btn btn-transparent color-red"
			title={t('delete')}
			onClick={(e) => deleteItem(e, record)}
		>
			<i className="fa fa-times" />
			<span className="icon-margin">{t('delete')}</span>
		</Button>
	);
};

export const DeleteRemote: React.FC<DeleteRemoteProps> = ({
	itemName, deleteEndpoint, requestParams, reload, id, pagination, errorSettings,
}) => {
	const { t } = useTranslation('translation');

	return (
		<Button
			className="btn btn-transparent color-red"
			title={t(`${localKey}.delete`)}
			onClick={() => handleDelete(t, itemName, deleteEndpoint ?? '', requestParams, reload, id, pagination, errorSettings)}
		>
			<i className="fa fa-times" />
			<span className="icon-margin">{t(`${localKey}.delete`)}</span>
		</Button>
	);
};

export const RequestButton: React.FC<RequestButtonProps> = ({
	itemName, updateEndpoint, requestParams, reload, disabled, message, icon,
}) => {
	const [loading, setLoading] = React.useState<boolean>(false);

	return (
		<Button
			className="btn btn-transparent"
			title={itemName}
			isLoading={loading}
			disabled={disabled}
			onClick={() => {
				setLoading(true);
				request(updateEndpoint, requestParams)
					.then(() => {
						reload();
						alertMessage(MessageType.success, `Success! ${message}`);
					})
					.catch((error) => {
						alertMessage(MessageType.error, error);
					})
					.finally(() => setLoading(false));
			}}
		>
			{icon}
			<span className={clsx(icon && 'icon-margin')}>{itemName}</span>
		</Button>
	);
};

export const EditButton: React.FC<EditButtonProps> = ({ onClick, linkPath }) => {
	const { t } = useTranslation('translation', { keyPrefix: localKey });
	if (onClick && !linkPath) {
		return (
			<button
				type="button"
				className="btn btn-transparent"
				title={t('edit')}
				onClick={onClick}
			>
				<i className="fa fa-pencil" />
				<span className="icon-margin">{t('edit')}</span>
			</button>
		);
	}

	if (linkPath) {
		return (
			<Link title={t('edit')} to={linkPath}>
				<i className="fa fa-pencil" />
				<span className="icon-margin">{t('edit')}</span>
			</Link>
		);
	}

	return null;
};

export const PrintButton: React.FC<PrintButtonProps> = ({ id }: PrintButtonProps) => {
	const { t } = useTranslation('translation', { keyPrefix: localKey });

	return (
		<ResourceButton
			className="btn btn-transparent"
			url={`/get-pet-tag?petId=${id}`}
		>
			<>
				<i className="fa fa-download btn-icon" aria-hidden="true" />
				<span className="icon-margin">{t('print')}</span>
			</>
		</ResourceButton>
	);
};

export const ActionButtons: React.FC<OwnProps> = (props: OwnProps) => {
	const { t } = useTranslation('translation', { keyPrefix: 'table.columns' });
	const {
		actions,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		itemName,
	} = props;

	return (
		<MobileCell caption={t('actions')} fullWidth>
			<div className="user-actions-button">
				<Dropdown
					trigger={['click']}
					overlay={(
						<Menu>
							{
								actions.map(({ key, element }) => (
									<Menu.Item key={key}>
										{element}
									</Menu.Item>
								))
							}
						</Menu>
					)}
				>
					<button
						type="button"
						className="btn btn-transparent"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
						style={{ textAlign: 'center' }}
					>
						<i className="fa fa-ellipsis-v" aria-hidden="true" />
						<span className="visually-hidden">Open menu</span>
					</button>
				</Dropdown>
			</div>
		</MobileCell>
	);
};
