import clsx from 'clsx';

export const NEW_PET_ID = -1;
export const defaultTab = 'service-info';
export const getPetPath = 'getPet';
export const createPetPath = 'createPet';
export const updatePetPath = 'updatePet';
export const deletePetPath = 'deletePet';

export const defaultPetEditorTab = 'service-info';

export const getInputClassname = (type: string | undefined):string => clsx('form-group', type && 'pet-editor__horizontal-input');
export const getShortenInputClassname = (type: string | undefined):string => clsx(
	'form-group',
	type && 'pet-editor__horizontal-input',

	'pet-editor__horizontal-input--shorten',
);
