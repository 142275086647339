import React from 'react';
import clsx from 'clsx';

import { BackButton } from '@app/components/UI/Header/PageHeaders/BackButton';

interface Props {
	children: React.ReactNode;
}

interface ListComponent extends React.FC<Props> {
	Header: ListHeaderComponent;
}

type ListHeaderType = React.FC<Props>;
type ListHeaderProps = Props & { className?: string };
type ListHeaderTitleProps = Props & { backPath?: string }
type ListHeaderTitleType = React.FC<ListHeaderTitleProps>;
interface ListHeaderComponent extends React.FC<ListHeaderProps> {
	Title: ListHeaderTitleType;
	Subtitle: ListHeaderType;
	Actions: ListHeaderType;
}

export const List: ListComponent = ({ children }: Props) => {
	return <>{children}</>;
};

const ListHeader: ListHeaderComponent = ({ children, className }: ListHeaderProps) => {
	return <div className={clsx('x-list__header', className)}>{children}</div>;
};

const ListHeaderTitle: ListHeaderTitleType = ({ children, backPath }: ListHeaderTitleProps) => {
	return (
		<div>
			{backPath && <BackButton backPath={backPath} />}
			<h1 className="x-list__header__title">{children}</h1>
		</div>
	);
};

const ListHeaderSubtitle: ListHeaderType = ({ children }: Props) => {
	return <h2 className="x-list__header__subtitle">{children}</h2>;
};

const ListHeaderActions: ListHeaderType = ({ children }: Props) => {
	return <div className="x-list__header__actions">{children}</div>;
};

List.Header = ListHeader;
ListHeader.Title = ListHeaderTitle;
ListHeader.Subtitle = ListHeaderSubtitle;
ListHeader.Actions = ListHeaderActions;
