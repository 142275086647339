import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, FieldProps } from 'formik';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { PostalCodeAutocomplete } from '@app/components/UI/Inputs/Address/PostalCodeAutocomplete';
import { PostalCode } from '@app/objects/PostalCode';
import { RegionSelect } from '@app/components/UI/Inputs/Address/RegionSelect';
import { iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import { CardSize, Card, getCardFields } from '@app/components/UI/Cards/Card';
import { FormValues } from '@app/components/Pages/ClinicPetEditor/types';
import { BaseSectionProps, getDeliveryInfoFields } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/ViewContent';
import { ApplicationState } from '@app/store';
import { addressService } from '@app/services/AddressService';

export const DeliveryDetails: React.FC<Partial<BaseSectionProps>> = ({ mode }: Partial<BaseSectionProps>) => {
	const { t } = useTranslation();
	const clinic = useSelector((state: ApplicationState) => state.clinic.item);
	const country = clinic?.country?.alpha3 ?? 'unknown';
	const details = addressService.provide(country);

	return (
		<Card
			name="Delivery Details"
			size={CardSize.Medium}
			mode={mode}
			fullWidth

			editContent={(
				<>
					<div className="row">
						<Field name="deliveryAddress">
							{(fieldProps: FieldProps<FormValues>) => (
								<FormikInput
									containerClassName="form-group col-sm-6"
									fieldProps={fieldProps}
									title={`${t(iKey('labels.delivery-address-1'))}*`}
								/>
							)}
						</Field>
						<Field name="deliveryAddress2">
							{(fieldProps: FieldProps<FormValues>) => (
								<FormikInput
									containerClassName="form-group col-sm-6"
									fieldProps={fieldProps}
									title={t(iKey('labels.delivery-address-2'))}
								/>
							)}
						</Field>
					</div>
					<div className="row">
						<Field name="deliveryZip">
							{(fieldProps: FieldProps<FormValues>) => (
								<FormikInput
									fieldProps={fieldProps}
									title={`${t(iKey(`labels.delivery-${details.mailKey}`))}*`}
									containerClassName="form-group col-sm-4"
									render={(fieldProps: FieldProps<string, FormValues>) => (
										<PostalCodeAutocomplete
											value={fieldProps.field.value}
											onChange={(value: string) =>
												fieldProps.form.setFieldValue(fieldProps.field.name as keyof FormValues, value, false)}
											onSelect={(value, option: PostalCode) => {
												fieldProps.form.setValues({
													...fieldProps.form.values,
													deliveryZip: option.zip,
													deliveryCity: option.city,
													deliveryStateId: option.stateId,
												});
											}}
											country={country}
										/>
									)}
								/>
							)}
						</Field>
						<Field name="deliveryCity">
							{(fieldProps: FieldProps<FormValues>) => (
								<FormikInput
									fieldProps={fieldProps}
									title={`${t(iKey('labels.delivery-city'))}*`}
									containerClassName="form-group col-sm-4"
								/>
							)}
						</Field>
						<Field name="deliveryStateId">
							{(fieldProps: FieldProps<FormValues>) => (
								<FormikInput
									fieldProps={fieldProps}
									containerClassName="form-group col-sm-4"
									title={`${t(iKey(`labels.delivery-${details.regionKey}`))}*`}
									render={({ field, form }) => (
										<RegionSelect
											value={field.value}
											onChange={(state?: number) => {
												form.setFieldValue(field.name, state);
												form.setFieldValue('deliveryState', null);
											}}

											country={country}
										/>
									)}
								/>
							)}
						</Field>
					</div>
				</>
			)}
			viewContent={(
				<Field name="specialInstructions">
					{({ form }: FieldProps<FormValues>) => getCardFields(getDeliveryInfoFields(form.values, t, details))}
				</Field>
			)}
		/>
	);
};
