import { Nullable } from '@common/typescript/objects/Nullable';
import { WithId } from '@common/typescript/objects/WithId';

import { DeliveryType, ServiceType } from '@app/objects/Pet';
import { DiscountType, PriceKind, PriceType } from '@app/objects/Price';
import { FormValues } from '@app/components/Various/PriceEditor/PriceEditor';

export interface CreatePriceMessage {
	name: string;
	description: string;
	unit: Nullable<DiscountType>;
	applyTo?: Nullable<PriceKind>;

	clinicId: number | null;
	crematoryId: number;

	petSpecieId: number | null;
	specialServiceId: Nullable<Array<number> | number>;
	pickupServiceId: Nullable<Array<number> | number>;
	inventoryItemId: Nullable<Array<Nullable<number>> | number>;
	deliveryType: DeliveryType | null;

	priceType: PriceType;
	serviceType: ServiceType;
	priceKind: PriceKind;

	from: number;
	to: number;
	value: number;
	extra: number;

	batchCount: number;
	batchPrice: number;

	maxSize?: Nullable<number>;
	isDefault: boolean;
	selectAll: boolean;
	filters: {
		name: string;
		ancestorId: Array<number>;
	};
}

export interface UpdatePriceMessage extends WithId {
	name: string;
	description: string;
	unit: Nullable<DiscountType>;
	applyTo?: Nullable<PriceKind>;

	clinicId: Nullable<number>;
	crematoryId: number;

	specialServiceId: Nullable<number>;
	pickupServiceId: Nullable<number>;
	inventoryItemId: Nullable<number>;
	deliveryType: Nullable<DeliveryType>;
	petSpecieId: Nullable<number>;

	priceType: PriceType;
	serviceType: ServiceType;

	from: number;
	to: number;
	value: number;
	extra: number;

	batchCount: number;
	batchPrice: number;
	maxSize?: Nullable<number>;
	isDefault: boolean;
}

export const noUrnId = -13; // Id of local item used for 'no urn' price

function toProductMessage(values: FormValues) {
	const isPriceForNoItem = (values.inventoryItemId as Array<number>)[0] === noUrnId;

	return {
		batchCount: isPriceForNoItem ? 0 : values.batchCount,
		batchPrice: isPriceForNoItem ? 0 : values.batchPrice,
		inventoryItemId: isPriceForNoItem ? [null] : values.inventoryItemId,
		filters: values.filters,
		selectAll: values.selectAll ?? false,
	};
}

export function toCreateMessage(values: FormValues): Partial<CreatePriceMessage> {
	const message = {
		value: values.value,
		clinicId: values.clinicId,
		crematoryId: values.crematoryId,
		priceKind: values.priceKind,
		priceType: values.priceType,
		serviceType: values.serviceType,
	};

	switch (values.priceKind) {
	case PriceKind.BasePrice:
		return {
			...message,
			from: values.from,
			to: values.to,
			extra: values.extra,
			petSpecieId: values.petSpecieId,
		};

	case PriceKind.SpecialServicePrice:
		return {
			...message,
			batchCount: values.batchCount,
			batchPrice: values.batchPrice,
			isDefault: values.isDefault,
			specialServiceId: values.specialServiceId,
			filters: values.filters,
			selectAll: values.selectAll ?? false,
		};

	case PriceKind.PickupPrice:
		return {
			...message,
			pickupServiceId: values.pickupServiceId,
			filters: values.filters,
			selectAll: values.selectAll ?? false,
		};

	case PriceKind.UrnPrice:
	case PriceKind.ProductPrice:
		return {
			...message,
			...toProductMessage(values),
		};

	case PriceKind.Discount:
		return {
			...message,
			name: values.name,
			description: values.description,
			unit: values.unit,
			applyTo: values.applyTo,
		};

	case PriceKind.DeliveryPrice:
		return {
			...message,
			deliveryType: values.deliveryType,
		};

	case PriceKind.Engraving:
		return {
			...message,
			maxSize: values.maxSize,
		};

	case PriceKind.RushFee:
		return message;

	default:
		throw new TypeError('Unknown Price Kind');
	}
}

function isNoItemPrice(price: FormValues): boolean {
	if (price.priceKind !== PriceKind.ProductPrice && price.priceKind !== PriceKind.UrnPrice) return false;

	return price.inventoryItemId === noUrnId;
}

export function toUpdateMessage(values: FormValues): UpdatePriceMessage {
	const isPriceForNoItem = isNoItemPrice(values);

	return {
		id: values.id,

		name: values.name,
		description: values.description,
		unit: values.unit,
		applyTo: values.applyTo,

		clinicId: values.clinicId,
		crematoryId: values.crematoryId,

		specialServiceId: values.specialServiceId as Nullable<number>,
		inventoryItemId: isPriceForNoItem ? null : values.inventoryItemId as Nullable<number>,
		pickupServiceId: values.pickupServiceId as Nullable<number>,
		deliveryType: values.deliveryType,
		petSpecieId: values.petSpecieId,

		priceType: values.priceType,
		serviceType: values.serviceType,

		from: values.from,
		to: values.to,
		value: values.value,
		extra: values.extra,

		batchCount: isPriceForNoItem ? 0 : values.batchCount,
		batchPrice: isPriceForNoItem ? 0 : values.batchPrice,
		maxSize: values.maxSize,
		isDefault: values.isDefault,
	};
}
