import React from 'react';

import Input from 'antd/lib/input';
import { Field, FieldProps } from 'formik';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { LocalSelect } from '@app/components/UI/Inputs/LocalSelect';
import { FormValues } from '@app/components/Various/PriceEditor/PriceEditor';
import { discountLimit, discountUnit } from '@app/components/UI/Inputs/LocalSelectOptions';
import { Stack, StackDirection } from '@app/components/Layout/Stack';

export const DiscountComponent: React.FC = () => (
	<Stack
		direction={StackDirection.Vertical}
		className="row"
		gap={2}
	>
		<Field name="name">
			{(fieldProps: FieldProps<FormValues>) => (
				<FormikInput
					fieldProps={fieldProps}
					title="Name*"
					containerClassName="col-sm-12"
					render={(formik: FieldProps) => (
						<Input
							value={formik.field.value}
							onChange={(event) => formik.form.setFieldValue(formik.field.name, event.target.value)}
							type="text"
						/>
					)}
				/>
			)}
		</Field>

		<Stack
			direction={StackDirection.Horizontal}
			className="stack-container"
			gap={0}
		>
			<Field name="unit">
				{(fieldProps: FieldProps<number, FormValues>) => (
					<FormikInput
						fieldProps={fieldProps}
						title="Unit*"
						containerClassName="col-sm-4"
						render={({ field, form }) => (
							<LocalSelect
								value={fieldProps.field.value}
								onChange={(value: number) => form.setFieldValue(field.name, value, false)}
								options={discountUnit}
								deselectType=""
								filterName="unit"
								fieldName=""
								placeholder="Select the unit"
								allowClear={false}
							/>
						)}
					/>
				)}
			</Field>
			<Field name="applyTo">
				{(fieldProps: FieldProps<number, FormValues>) => (
					<FormikInput
						fieldProps={fieldProps}
						title="Apply To"
						containerClassName="col-sm-4"
						render={({ field, form }) => (
							<LocalSelect
								value={field.value ?? -1}
								onChange={(value: number) => form.setFieldValue(field.name, value >= 0 ? value : null, false)}
								options={discountLimit}
								deselectType=""
								filterName="Apply To"
								fieldName=""
								placeholder="Select price type to restrict discount to"
								allowClear={false}
							/>
						)}
					/>
				)}
			</Field>
			<Field name="value">
				{(fieldProps: FieldProps<FormValues>) => (
					<FormikInput
						fieldProps={fieldProps}
						title="Value*"
						containerClassName="col-sm-4"
						render={(formik: FieldProps) => (
							<Input
								value={formik.field.value}
								onChange={(event) => formik.form.setFieldValue(formik.field.name, event.target.valueAsNumber)}

								type="number"
								min={0}
								inputMode="decimal"
							/>
						)}
					/>
				)}
			</Field>
		</Stack>

		<Field name="description">
			{(fieldProps: FieldProps<FormValues>) => (
				<FormikInput
					fieldProps={fieldProps}
					title="Description"
					containerClassName="col-sm-12"
					render={({ field, form }: FieldProps) => (
						<Input.TextArea
							value={field.value}
							onChange={(event) => form.setFieldValue(field.name, event.target.value)}
						/>
					)}
				/>
			)}
		</Field>
	</Stack>
);
