import * as React from 'react';
import { useSelector } from 'react-redux';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@common/typescript/objects/Nullable';

import { TextSearch, TextSearchTooltip } from '@app/components/UI/Inputs/TextSearch';
import { CrematorySelect } from '@app/components/UI/Inputs/CrematorySelect';
import { PetSpecieSelect } from '@app/components/UI/Inputs/PetSpecieSelect';
import { BreedSelect } from '@app/components/UI/Inputs/PetBreedSelect';
import { ClinicSelect } from '@app/components/UI/Inputs/ClinicSelect';
import { StatusSelect } from '@app/components/UI/Inputs/StatusSelect';
import { DateSelect } from '@app/components/UI/Inputs/DateSelect';
import { Filters } from '@app/components/UI/Filters/FilterHook';
import { MaskedSearch } from '@app/components/UI/Inputs/MaskedSearch';
import { phoneMask } from '@app/components/Utils/Utils';
import { DeliveryTypeSelect } from '@app/components/UI/Inputs/DeliveryTypeSelect';
import { SpecialServiceSelect } from '@app/components/UI/Inputs/SpecialServiceSelect';
import { LocalSelect } from '@app/components/UI/Inputs/LocalSelect';
import { PetSourceSelect } from '@app/components/UI/Inputs/PetSourceSelect';
import { PetIssueSelect } from '@app/components/UI/Inputs/PetIssueSelect';
import {
	crematoryUserRoles,
	flagStatus,
	priceType,
	serviceTypes,
	serviceTypesWithAll,
	userRoles,
	invoiceStatus,
	petSource,
	hasIssues,
	special,
	rush,
	onHoldAdmin,
	onHoldCrematory,
	priceTypeWithAll,
	statusFunction,
	customer,
	itemType,
	priceKindOptions,
	isLinkedToQuickbooksOptions,
	adminServiceTypes,
	inventoryCategory,
} from '@app/components/UI/Inputs/LocalSelectOptions';
import { isPickDefault } from '@app/store/SelectList/SelectsInterfaces';
import { ClinicSelectInfo } from '@app/objects/Clinic';
import { CrematoryListInfo, CrematoryMachine } from '@app/objects/Crematory';
import { Status } from '@app/objects/Status';
import { SpecialService } from '@app/objects/SpecialService';
import { ApplicationState } from '@app/store';
import { UserListInfo, UserRole } from '@app/objects/User';
import { UserSelect } from '@app/components/UI/Inputs/UserSelect';
import { RouteSelect } from '@app/components/UI/Inputs/RouteSelect';
import { MachineSelect } from '@app/components/UI/Inputs/MachineSelect';
import { Route } from '@app/objects/Route';
import { statusFunctionGroup } from '@app/components/UI/Inputs/LocalSelectGroup';
import { onChangeCategoryFilter, onFilterValue } from '@app/objects/Inventory';
import { useCrematory } from '@app/hooks/useCrematory';

export type onChangeValue = string | number | Array<number> | undefined | Nullable<number> |
	{from: number|undefined, to: number|undefined} | [number|undefined, number|undefined] | boolean;

const searchFieldClassname = 'search-field';

const getMultipleSelectClassName = (value, queryLength) => ((value || value === 0 || queryLength > 0)
	? 'select-with-inner-label--opened' : 'select-with-inner-label--closed');
export const getSingleSelectClassName = (value: number | Array<number> | string | undefined, queryLength: number): string =>
	((value || value === 0 || queryLength > 0)
		? 'select-with-inner-label--opened--single' : 'select-with-inner-label--closed');

interface FiltersList {
	id: JSX.Element,
	query: JSX.Element,
	name: JSX.Element,
	ownerFirstName: JSX.Element,
	ownerLastName: JSX.Element,
	crematories: JSX.Element,
	contactPerson: JSX.Element,
	petSpecie: JSX.Element,
	petSource: JSX.Element,
	petBreed: JSX.Element,
	clinic: JSX.Element,
	invoiceNumber: JSX.Element,
	role: JSX.Element,
	crematoryRole: JSX.Element,
	email: JSX.Element,
	phone: JSX.Element,
	serviceType: JSX.Element,
	serviceTypeWithAll: JSX.Element,
	status: JSX.Element,
	excludingStatus: JSX.Element,
	date: JSX.Element,
	deliveryType: JSX.Element,
	priceType: JSX.Element,
	priceTypeWithAll: JSX.Element,
	specialServices: JSX.Element,
	enabled: JSX.Element,
	invoiceStatus: JSX.Element,
	orderNumber: JSX.Element,
	dueDate: JSX.Element,
	hasIssues: JSX.Element,
	rush: JSX.Element,
	onHold: JSX.Element,
	isSpecial: JSX.Element,
	myPets: JSX.Element,
	user: JSX.Element,
	internalId: JSX.Element,
	statusFunction: JSX.Element,
	category: JSX.Element,
	isCustomer: JSX.Element,
	route: JSX.Element,
	itemType: JSX.Element,
	machineId: JSX.Element,
	priceKind: JSX.Element,
	isLinkedToQuickbooks: JSX.Element,
}

const defaultCode = '+1';
const priceKindKey = 'price-kind';

export const getFilters = (
	filters: Partial<Filters>,
	onChange: (name: string, value: onChangeValue) => void,
	onDeselect: (fieldName: string, type: string, id?: number) => void,
	submit: () => void,
	defaultParams?: Record<string, unknown>,
	crematoryId?: number,
	customProperties?: Partial<Record<keyof Filters, Record<string, unknown>>>,
): FiltersList => {
	const { t } = useTranslation('translation');
	const user = useSelector((state: ApplicationState) => state.login.user);
	const role = user?.role;
	const crematoryStore = useSelector((state: ApplicationState) => state.crematory.item);
	const crematory = useCrematory();
	const phoneCode = role === UserRole.Crematory
		? crematoryStore?.country?.phoneCode
		: defaultCode;

	const translate = React.useCallback((key) => t(`filters.${key}`), []);

	return {
		id: <TextSearch
			fieldName="id"
			title={`#${translate('id')}`}
			placeholder={translate('id')}
			value={filters.id}
			onChange={(value) => onChange('id', value)}
			onDeselect={onDeselect}
			submit={submit}
			className={searchFieldClassname}
		/>,
		query: (
			<TextSearchTooltip
				onChange={(value) => onChange('query', value)}
				placeholder={translate('query')}
				value={filters.query}
				fieldName="query"
				title={translate('query')}
				onDeselect={onDeselect}
				submit={submit}
				className={searchFieldClassname}
			/>
		),
		name: <TextSearch
			onChange={(value) => onChange('name', value)}
			placeholder={translate('name')}
			value={filters.name}
			fieldName="name"
			title={translate('name')}
			onDeselect={onDeselect}
			submit={submit}
			className={searchFieldClassname}
		/>,
		ownerFirstName: <TextSearch
			onChange={(value: string) => onChange('ownerFirstName', value)}
			placeholder={translate('owner-first-name')}
			value={filters.ownerFirstName}
			fieldName="ownerFirstName"
			title={translate('owner-first-name')}
			onDeselect={onDeselect}
			submit={submit}
			className={searchFieldClassname}
		/>,
		ownerLastName: <TextSearch
			onChange={(value: string) => onChange('ownerLastName', value)}
			placeholder={translate('owner-last-name')}
			value={filters.ownerLastName}
			fieldName="ownerLastName"
			title={translate('owner-last-name')}
			onDeselect={onDeselect}
			submit={submit}
			className={searchFieldClassname}
		/>,
		crematories: (
			<CrematorySelect
				value={filters.crematories}
				onChange={(values: Array<number> | undefined) => onChange('crematories', values)}
				onDeselect={(id: number) => onDeselect('crematories', 'id', id)}

				className={searchFieldClassname}
				selectClassName="select-with-inner-label--closed"
				activeClassName="select-with-inner-label--opened"

				placeholder={translate('crematories')}
				pickDefault={(store: Array<CrematoryListInfo>) => isPickDefault(store)}
				multiple
				noPreselect
			/>
		),
		contactPerson: <TextSearch
			onChange={(value) => onChange('contactPerson', value)}
			placeholder={translate('contact-person')}
			value={filters.contactPerson}
			title={translate('contact-person')}
			fieldName="contactPerson"
			onDeselect={onDeselect}
			submit={submit}
			className={searchFieldClassname}
		/>,
		petSpecie: <PetSpecieSelect
			onChange={(value) => onChange('petSpecie', [value])}
			value={filters.petSpecie}
			onDeselect={onDeselect}
			className={searchFieldClassname}
			selectClassName={(value, length) => getSingleSelectClassName(value, length)}
		/>,
		petSource: <PetSourceSelect
			onChange={(value) => onChange('petSource', value)}
			value={filters.petSource}
			options={petSource}
			onDeselect={onDeselect}
			className={searchFieldClassname}
			selectClassName={(value, length) => getSingleSelectClassName(value, length)}
		/>,
		hasIssues: <PetIssueSelect
			onChange={(value) => onChange('hasIssues', value)}
			value={filters.hasIssues}
			options={hasIssues}
			onDeselect={onDeselect}
			className={searchFieldClassname}
			selectClassName={(value, length) => getSingleSelectClassName(value, length)}
		/>,
		petBreed: (
			<BreedSelect
				value={filters.petBreed}
				onChange={(values: Array<number> | undefined) => onChange('petBreed', values)}
				onDeselect={(value: number) => onDeselect('petSpecie', 'id', value)}
				petSpecieId={filters.petSpecie ? filters.petSpecie[0] : undefined}

				className={searchFieldClassname}
				selectClassName="select-with-inner-label--closed"
				activeClassName="select-with-inner-label--opened"
				disabled={!filters.petSpecie}
				placeholder={translate('pet-breed')}
				multiple
			/>
		),
		clinic: (
			<ClinicSelect
				value={filters.clinic}
				onChange={(value: Array<number> | undefined) => onChange('clinic', value)}
				onDeselect={(value: number) => onDeselect('clinic', 'id', value)}

				placeholder={translate('clinic')}
				className={searchFieldClassname}
				selectClassName="select-with-inner-label--closed"
				activeClassName="select-with-inner-label--opened"
				isDefault={false}
				pickDefault={(store: Array<ClinicSelectInfo>) => isPickDefault(store)}
				multiple
				markCustomer={role === UserRole.Crematory || role === UserRole.CrematoryUser}
				{...defaultParams}
			/>),
		invoiceNumber: <TextSearch
			onChange={(value) => onChange('invoiceNumber', value)}
			placeholder={translate('invoice-number')}
			value={filters.invoiceNumber}
			title={translate('invoice-number')}
			fieldName="invoiceNumber"
			onDeselect={onDeselect}
			submit={submit}
			className={searchFieldClassname}
		/>,
		role: <LocalSelect
			value={filters.role}
			onChange={(value) => onChange('role', value)}
			multiple
			onDeselect={onDeselect}
			options={userRoles}
			deselectType="id"
			filterName="role"
			fieldName="User role"
			placeholder={translate('role')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getMultipleSelectClassName(value, length)}
		/>,
		crematoryRole: <LocalSelect
			value={filters.role}
			onChange={(value) => onChange('role', value)}
			multiple
			onDeselect={onDeselect}
			options={crematoryUserRoles}
			deselectType="id"
			filterName="role"
			fieldName={translate('role')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getMultipleSelectClassName(value, length)}
		/>,
		email: <TextSearch
			onChange={(value) => onChange('email', value)}
			placeholder={translate('email')}
			value={filters.email}
			title={translate('email')}
			fieldName="email"
			onDeselect={onDeselect}
			submit={submit}
			className={searchFieldClassname}
		/>,
		phone: <MaskedSearch
			fieldName="phone"
			guide
			onChange={(value) => onChange('phone', value)}
			mask={phoneMask(phoneCode ?? defaultCode)}
			onDeselect={onDeselect}
			placeholder={translate('phone')}
			title={translate('phone')}
			className={searchFieldClassname}
			value={filters.phone}
		/>,
		serviceType: <LocalSelect
			value={filters.serviceType}
			onChange={(value) => onChange('serviceType', value)}
			multiple
			options={role === UserRole.Admin ? adminServiceTypes : serviceTypes(crematory)}
			onDeselect={onDeselect}
			deselectType="id"
			filterName="serviceType"
			fieldName="Service type"
			placeholder={translate('service-type')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getMultipleSelectClassName(value, length)}
		/>,
		serviceTypeWithAll: <LocalSelect
			value={filters.serviceType}
			onChange={(value) => onChange('serviceType', value)}
			multiple
			options={serviceTypesWithAll}
			onDeselect={onDeselect}
			deselectType="id"
			filterName="serviceType"
			fieldName="Service type"
			placeholder={translate('service-type-with-all')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getMultipleSelectClassName(value, length)}
		/>,
		status: (
			<StatusSelect
				value={filters.status}
				onChange={(value) => onChange('status', value)}
				onDeselect={(value: number) => onDeselect('status', 'id', value)}

				placeholder={translate('status')}
				className={searchFieldClassname}
				selectClassName="select-with-inner-label--closed"
				activeClassName="select-with-inner-label--opened"
				crematoryId={crematoryId}

				multiple
				pickDefault={(store: Array<Status>) => isPickDefault(store)}
			/>
		),
		excludingStatus: (
			<StatusSelect
				value={filters.excludingStatus}
				onChange={(value) => onChange('excludingStatus', value)}
				onDeselect={(value: number) => onDeselect('excludingStatus', 'id', value)}

				placeholder={translate('excluding-status')}
				className={searchFieldClassname}
				selectClassName="select-with-inner-label--closed"
				activeClassName="select-with-inner-label--opened"
				crematoryId={crematoryId}

				multiple
				pickDefault={(store: Array<Status>) => isPickDefault(store)}
			/>
		),
		date: <DateSelect
			onChange={(from, to) => onChange('date', { from, to })}
			onDeselect={onDeselect}
			value={filters.date}
			fieldName={translate('date')}
			filterName="date"
			className={searchFieldClassname}
			format={user?.localeUserPreferences?.dateFormat}
		/>,
		deliveryType: <DeliveryTypeSelect
			multiple
			value={filters.deliveryType}
			onChange={(value) => onChange('deliveryType', value)}
			onDeselect={onDeselect}
			className={searchFieldClassname}
			selectClassName={(value, length) => getMultipleSelectClassName(value, length)}
		/>,
		priceType: <LocalSelect
			multiple
			value={filters.priceType}
			onChange={(value) => onChange('priceType', value)}
			onDeselect={onDeselect}
			options={priceType(t)}
			deselectType="id"
			filterName="priceType"
			fieldName="Price type"
			placeholder={translate('price-type')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getMultipleSelectClassName(value, length)}
		/>,
		priceTypeWithAll: <LocalSelect
			multiple
			value={filters.priceType}
			onChange={(value) => onChange('priceType', value)}
			onDeselect={onDeselect}
			options={priceTypeWithAll(t)}
			deselectType="id"
			filterName="priceType"
			fieldName="Price type"
			placeholder={translate('price-type-with-all')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getMultipleSelectClassName(value, length)}
		/>,
		specialServices: <SpecialServiceSelect
			value={filters.specialServices}
			onChange={(value) => onChange('specialServices', value)}
			onDeselect={(value: number) => onDeselect('specialServices', 'id', value)}

			placeholder={translate('special-services')}
			className={searchFieldClassname}
			selectClassName="select-with-inner-label--closed"
			activeClassName="select-with-inner-label--opened"

			multiple
			pickDefault={(store: Array<SpecialService>) => isPickDefault(store)}
		/>,
		enabled: <LocalSelect
			value={filters.enabled}
			onChange={(value) => onChange('enabled', value)}
			onDeselect={onDeselect}
			options={flagStatus}
			deselectType="text"
			filterName="enabled"
			fieldName="Status"
			placeholder={translate('status')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getSingleSelectClassName(value, length)}
		/>,
		invoiceStatus: <LocalSelect
			value={filters.invoiceStatus}
			onChange={(value) => onChange('invoiceStatus', value)}
			onDeselect={onDeselect}
			options={invoiceStatus}
			deselectType="text"
			filterName="invoiceStatus"
			fieldName="Invoice type"
			placeholder={translate('invoice-status')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getMultipleSelectClassName(value, length)}
		/>,
		orderNumber: <TextSearch
			onChange={(value) => onChange('orderNumber', value)}
			placeholder={translate('order-number')}
			value={filters.orderNumber}
			fieldName="orderNumber"
			title={translate('order-number')}
			onDeselect={onDeselect}
			submit={submit}
			className={searchFieldClassname}
		/>,
		dueDate: <DateSelect
			onChange={(from, to) => onChange('dueDate', { from, to })}
			onDeselect={onDeselect}
			value={filters.dueDate}
			fieldName={translate('due-date')}
			filterName="dueDate"
			className={searchFieldClassname}
		/>,
		rush: <LocalSelect
			value={filters.rush === undefined ? undefined : Number(filters.rush)}
			onChange={(value) => onChange('rush', value)}
			onDeselect={onDeselect}
			options={rush}
			deselectType="text"
			filterName="rush"
			fieldName="Rush"
			placeholder={translate('rush')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getSingleSelectClassName(value, length)}
		/>,
		onHold: <LocalSelect
			value={filters.onHold}
			onChange={(value) => onChange('onHold', value)}
			onDeselect={onDeselect}
			options={role === UserRole.Admin ? onHoldAdmin : onHoldCrematory}
			deselectType="text"
			filterName="onHold"
			fieldName="onHold"
			placeholder={translate('on-hold')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getSingleSelectClassName(value, length)}
		/>,
		isSpecial: <LocalSelect
			value={filters.isSpecial === undefined ? undefined : Number(filters.isSpecial)}
			onChange={(value) => onChange('isSpecial', value)}
			onDeselect={onDeselect}
			options={special}
			deselectType="text"
			filterName="isSpecial"
			fieldName="Special"
			placeholder={translate('is-special')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getSingleSelectClassName(value, length)}
		/>,
		myPets: (
			<Checkbox
				checked={filters.myPets ?? false}
				onChange={(event: CheckboxChangeEvent) => {
					if (event.target.checked) {
						onChange('myPets', event.target.checked);
					} else {
						onDeselect('myPets', 'text');
					}
				}}
				className="clinic-checkbox-myPets--filters"
			>
				{translate('my-pets')}
			</Checkbox>
		),
		user: (
			<UserSelect
				value={filters.user}
				onChange={(value) => onChange('user', value)}
				onDeselect={(value: number) => onDeselect('user', 'id', value)}

				placeholder={translate('user')}
				className={searchFieldClassname}
				selectClassName="select-with-inner-label--closed"
				activeClassName="select-with-inner-label--opened"

				multiple
				pickDefault={(store: Array<UserListInfo>) => isPickDefault(store)}
			/>
		),
		internalId: (
			<TextSearch
				fieldName="internalId"
				title={translate('internal-id')}
				placeholder={translate('internal-id')}
				value={filters.internalId}
				onChange={(value: string) => onChange('internalId', value)}
				onDeselect={onDeselect}
				submit={submit}
				className={searchFieldClassname}
			/>
		),
		statusFunction: <LocalSelect
			value={filters.statusFunction}
			onChange={(value) => onChange('statusFunction', value)}
			onDeselect={onDeselect}
			options={Object.values(statusFunction)}
			deselectType="text"
			filterName="statusOrderFunction"
			fieldName="Status Order Function"
			placeholder={translate('status-function')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getSingleSelectClassName(value, length)}
			group={() => statusFunctionGroup(Object.values(statusFunction))}
		/>,
		category: <LocalSelect
			value={onFilterValue(filters.category)}
			onChange={(value) => {
				const ids = onChangeCategoryFilter(value);

				onChange('category', ids);
			}}
			onDeselect={onDeselect}
			options={inventoryCategory}
			deselectType="text"
			multiple
			filterName="inventoryCategory"
			fieldName="Inventory Category"
			placeholder={translate('category')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getMultipleSelectClassName(value, length)}
		/>,
		isCustomer: <LocalSelect
			value={filters.isCustomer === undefined ? undefined : Number(filters.isCustomer)}
			onChange={(value) => onChange('isCustomer', value)}
			onDeselect={onDeselect}
			options={customer}
			deselectType="text"
			filterName="customer"
			fieldName="Customer"
			placeholder={translate('is-customer')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getSingleSelectClassName(value, length)}
		/>,
		route: <RouteSelect
			value={filters.route}
			onChange={(value?: Array<number>) => onChange('route', value)}
			onDeselect={(value: number) => onDeselect('route', 'id', value)}

			placeholder={translate('route')}
			className={searchFieldClassname}
			selectClassName="select-with-inner-label--closed"
			activeClassName="select-with-inner-label--opened"
			isDefault={false}
			pickDefault={(store: Array<Route>) => isPickDefault(store)}
			multiple
		/>,
		itemType: <LocalSelect
			value={filters.itemType}
			onChange={(value) => onChange('itemType', value)}
			multiple
			options={itemType(t)}
			onDeselect={onDeselect}
			deselectType="id"
			filterName="itemType"
			fieldName={translate('item-type')}
			placeholder={translate('type')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getMultipleSelectClassName(value, length)}
		/>,
		machineId: <MachineSelect
			value={filters.machineId}
			onChange={(value?: Array<number>) => onChange('machineId', value)}
			onDeselect={(value: number) => onDeselect('machineId', 'id', value)}
			placeholder={translate('machine')}
			className={searchFieldClassname}
			selectClassName="select-with-inner-label--closed"
			activeClassName="select-with-inner-label--opened"
			pickDefault={(store: Array<CrematoryMachine>) => isPickDefault(store)}
			multiple
		/>,
		priceKind: <LocalSelect
			value={filters.priceKind}
			onChange={(value) => onChange('priceKind', value)}
			multiple
			options={priceKindOptions}
			onDeselect={onDeselect}
			deselectType="id"
			filterName="priceKind"
			fieldName={translate(priceKindKey)}
			placeholder={translate(priceKindKey)}
			className={searchFieldClassname}
			selectClassName={(value, length) => getMultipleSelectClassName(value, length)}
			{...customProperties?.priceKind}
		/>,
		isLinkedToQuickbooks: <LocalSelect
			value={filters.isLinkedToQuickbooks === undefined ? undefined : Number(filters.isLinkedToQuickbooks)}
			onChange={(value) => onChange('isLinkedToQuickbooks', value)}
			options={isLinkedToQuickbooksOptions}
			onDeselect={onDeselect}
			deselectType="id"
			filterName="isLinkedToQuickbooks"
			fieldName={translate('is-linked-to-quickbooks')}
			placeholder={translate('is-linked-to-quickbooks')}
			className={searchFieldClassname}
			selectClassName={(value, length) => getSingleSelectClassName(value, length)}
		/>,
	};
};
