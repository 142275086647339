import * as React from 'react';

import { Nullable } from '@common/typescript/objects/Nullable';

import {
	CoreSelect,
	CoreSelectProps,
	GroupProps,
} from '@app/components/UI/Inputs/CoreSelect';
import { InventoryItem, ItemCategory } from '@app/objects/Inventory';
import { PriceType } from '@app/objects/Price';
import { ServiceType } from '@app/objects/Pet';
import {
	ProductSelectFilter,
	isEmptyValue,
} from '@app/store/SelectList/SelectsInterfaces';
import { SelectRequestParams } from '@app/store/SelectList/ListActions';
import { EntityType } from '@app/store/SelectList/UtilityTypes';
import { GeneralKey } from '@app/store/SelectList/SelectList';

type Entity = EntityType<'products'>;
interface BaseProps {
	crematoryId: number;
	clinicId?: Nullable<number>;
	serviceType: ServiceType;
	priceType: PriceType;
	category: ItemCategory;
	parentId?: number;
	availableForCrematory?: boolean;

	disabled?: boolean;
	className?: string;
	selectClassName?: string;

	pickDefault?: (items: Array<Entity>) => number;
	onDeselect?: (id: number) => void;
	getName?: (item: Entity) => string;
	localOptions?: Array<Entity>;

	label?: string;
	placeholder?: string;
	allowClear?: boolean;

	withGroup?: boolean;
	withParent?: boolean;

	style?: React.CSSProperties;
}

interface SingleProps extends BaseProps {
	value?: number;
	onChange: (value: number | undefined) => void;
	multiple?: false;
}

interface MultipleProps extends BaseProps {
	value?: Array<number>;
	onChange: (value: Array<number> | undefined) => void;
	multiple: true;
}

type OwnProps = SingleProps | MultipleProps;

interface RequestParams extends SelectRequestParams<number> {
	withDefault: boolean;
	groupByPrice: boolean;
	withParent?: boolean;
}

const reqParams: Partial<RequestParams> = {
	withDefault: true,
	groupByPrice: false,
};

function shouldPickDefault(store, multiple, isEmptyValue, reqProps, value): boolean {
	return !store.isLoading && store.items?.length
		// eslint-disable-next-line sonarjs/no-all-duplicated-branches
		&& (multiple ? isEmptyValue?.(value) : isEmptyValue?.(value))
		&& reqProps.isEmpty(store.filters);
}

function getOrder(items: Array<Entity>) {
	return items.sort((a: InventoryItem, b: InventoryItem) => {
		if (Math.sign(a.id) !== Math.sign(b.id)) return a.id - b.id;

		if (a.name === b.name) return 0;

		return a.name < b.name ? -1 : 1;
	});
}

function group(items: Array<InventoryItem>): Array<GroupProps<InventoryItem>> {
	const result = new Map<number, GroupProps<InventoryItem>>();

	items.forEach((item: InventoryItem) => {
		const name = item.parent?.name ?? 'Default';
		const key = item.parentId ?? -1;

		if (result.has(key)) {
			result.get(key)?.options.push(item);
		} else {
			result.set(key, { title: name, options: [item] });
		}
	});

	return [...result.values()].sort((a) => (a.title === 'Default' ? -1 : 0));
}

export const ProductSelect = (props: OwnProps) => {
	const [query, setQuery] = React.useState<string>('');

	if (props.withGroup && props.withParent) reqParams.withParent = props.withParent;

	const filters: ProductSelectFilter = React.useMemo(() => {
		if (props.parentId) {
			return {
				search: query,
				parentId: [props.parentId],
			};
		}

		return {
			availableForClinic: props.clinicId ?? null,
			availableForCrematory: props.availableForCrematory,
			name: query,
			search: query,
			crematoryId: props.crematoryId,
			category: [props.category],
			serviceType: props.serviceType,
			priceType: props.priceType,
		};
	}, [query, props.crematoryId, props.availableForCrematory, props.serviceType, props.priceType, props.clinicId, props.category, props.parentId]);

	const properties: CoreSelectProps<number, 'products', RequestParams> = {
		...props,
		onSearch: setQuery,
		reqParams,
		filters,
		shouldPickDefault,
		store: 'products',
		storeKey: !props.parentId ? GeneralKey : props.parentId?.toString(),
		localOptions: props.localOptions,
		order: getOrder,
		isEmptyValue: (value: number | Array<number> | undefined) => {
			const options = props.localOptions ?? [];
			const isEmpty = isEmptyValue(value);

			if (typeof value !== 'number') return isEmpty;

			return options.findIndex((q: InventoryItem) => q.id === value) < 0 && isEmpty;
		},
		group: props.withGroup ? group : undefined,
		getName: props.getName,
	};

	return (
		<CoreSelect {...properties} />
	);
};
