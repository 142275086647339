import React from 'react';
import { useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';

import { ColumnProps } from 'antd/lib/table';

import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { MobileCell } from '@common/react/components/Pages/ItemsPage';
import { TableData } from '@common/react/smart components/Table/TableDataProvider';

import { WithPageWrap } from '@app/components/Pages/PageWrappers/WithPageWrap';
import { LabelPaper, LengthUnit } from '@app/objects/LabelPaper';
import { ActionBuilder, ActionFactoryParams } from '@app/components/Utils/ActionFactory';
import { RoutePaths } from '@app/utilities/RoutePathVars';
import { ApplicationState } from '@app/store';
import { UserRole } from '@app/objects/User';

import { ModalCopyContent } from '@app/components/Pages/Admin/Status/ModalCopyContent';

export function unitName(unit: LengthUnit) {
	switch (unit) {
	case LengthUnit.Centimeter:
		return 'cm';
	case LengthUnit.Millimeter:
		return 'mm';
	case LengthUnit.Inch:
		return 'in';

	default:
		return null;
	}
}

const columns: Array<ColumnProps<LabelPaper>> = TableBuilder.shape<LabelPaper>()
	.addColumn({
		title: 'Name',
		dataIndex: 'name',
		render: (name: string, record: LabelPaper) => (
			<MobileCell caption="Name" fullWidth>
				<Link
					title="Edit Paper"
					to={generatePath(RoutePaths.labelPaperEditor, { id: record.id, type: 'view' })}
				>
					{name}
				</Link>
			</MobileCell>
		),
	})
	.addColumn({
		title: 'Width x Height',
		dataIndex: 'width',
		align: 'center',
		render: (width: number, record: LabelPaper) => (
			<MobileCell caption="Width x Height" fullWidth>
				{width} x {record.height} {unitName(record.unit)}
			</MobileCell>
		),
	})
	.addColumn({
		title: 'Columns x Rows',
		dataIndex: 'columns',
		align: 'center',
		render: (columns: number, record: LabelPaper) => (
			<MobileCell caption="Columns x Rows" fullWidth>
				{columns} x {record.rows}
			</MobileCell>
		),
	})
	.addColumn({
		title: 'Top / Right / Bottom / Left',
		align: 'center',
		render: (_: unknown, record: LabelPaper) => (
			<MobileCell caption="Top / Right / Bottom / Left" fullWidth>
				{record.margins.top} / {record.margins.right} / {record.margins.bottom} / {record.margins.left} {unitName(record.unit)}
			</MobileCell>
		),
	})
	.build();

const noteColumn: Array<ColumnProps<LabelPaper>> = (
	TableBuilder.shape<LabelPaper>()
		.addColumn({
			title: 'Note',
			align: 'center',
			dataIndex: 'description',
			render: (text: string) => <MobileCell caption="Note" fullWidth>{text}</MobileCell>,
		})
		.build()
);

const actions = (params: ActionFactoryParams<LabelPaper>) => (
	new ActionBuilder()
		.addEdit(undefined, generatePath(RoutePaths.labelPaperEditor, { id: params.record.id, type: 'view' }))
		.addDelete('deleteLabelPaper', 'labelPaper', params.reload, params.record, params.pagination)
		.build()
);

export const LabelPaperList: React.FC = () => {
	const role = useSelector((state: ApplicationState) => state.login.user?.role);
	const [selectedIds, setSelectedIds] = React.useState<Array<number>>([]);

	const copyReqParams = React.useMemo(() => ({ ids: selectedIds }), [selectedIds]);

	return (
		<WithPageWrap<LabelPaper>
			columns={[...columns, ...noteColumn]}
			getListEndpoint="labelPaperList"
			itemName="paper"
			pageTitle="Label Paper"
			path={generatePath(RoutePaths.labelPaperEditor, { id: -1, type: 'edit' })}
			filterNames={['name']}
			withHeaderMargin
			actions={actions}

			copyProps={(data: TableData<LabelPaper>) => ({
				reload: data.reload,
				reqParams: copyReqParams,
				copyButton: role !== UserRole.Admin,
				copyEndpoint: 'copyLabelPapersFromAdmin',
				successMessage: 'Success! The Label Paper have been copied',
				errorMessage: 'Error occured while copying Label Paper:',
				title: 'Copy Label Paper from Admin',
				content: (modalState: boolean) => (
					<ModalCopyContent<LabelPaper>
						endpoint="availableForCopyLabelPapersList"
						columns={columns}
						selectedIds={selectedIds}
						onChange={setSelectedIds}
						modalState={modalState}
					/>
				),
				buttonCopyName: 'Copy from Admin',
				copyModalWidth: '60%',
				okButtonProps: { disabled: !selectedIds.length },
			})}
		/>
	);
};
