import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps } from 'formik';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { DiscountType, PriceKind, PriceType } from '@app/objects/Price';
import { FormValues } from '@app/components/Pages/ClinicPetEditor/types';
import { CardSize, getCardFields, Card } from '@app/components/UI/Cards/Card';
import { DiscountSelect } from '@app/components/UI/Inputs/DiscountSelect';
import { PhoneInput } from '@app/components/UI/Inputs/PhoneInput';
import { BaseSectionProps, getOwnerInfoFields } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/ViewContent';
import { ServiceType } from '@app/objects/Pet';
import { ApplicationState } from '@app/store';

const getLocalDiscountOptions = (crematoryId: number) => ([{

	id: -1,
	name: 'Regular',
	search: '',
	description: '',

	unit: DiscountType.Value,

	clinic: null,
	clinicId: null,
	crematory: null,
	crematoryId,

	priceKind: PriceKind.Discount,
	priceType: PriceType.All,
	serviceType: ServiceType.All,

	deliveryType: null,
	inventoryItem: null,
	inventoryItemId: null,
	petSpecie: null,
	petSpecieId: null,
	specialService: null,
	specialServiceId: null,
	pickupServiceId: null,

	batchCount: 0,
	batchPrice: 0,
	isDefault: false,

	value: 0,
	extra: 0,
	from: 0,
	to: 0,
}]);

export const OwnerDetails: React.VFC<BaseSectionProps> = (props: BaseSectionProps) => {
	const { t } = useTranslation('translation', { keyPrefix: 'editors.clinic-pet-editor.cards.owner-info' });

	return (
		<Card
			name="Owner"
			size={CardSize.Medium}
			mode={props.mode}

			editContent={(
				<>
					<Field name="discountId">
						{(fieldProps: FieldProps<FormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t('owner-type')}
								containerClassName=""
								render={({ field, form }: FieldProps<number, FormValues>) => (
									<DiscountSelect
										allowClear={false}
										disabled={props.crematoryId < 1
											|| fieldProps.form.values.serviceType === undefined || fieldProps.form.values.serviceType < 0}
										priceType={PriceType.Wholesale}
										serviceType={form.values.serviceType!}
										value={!field.value ? -1 : field.value}
										onChange={(value) => form.setFieldValue(field.name, value === -1 ? null : value)}
										localOptions={getLocalDiscountOptions(props.crematoryId)}
										placeholder="Select the Owner Type"
									/>
								)}
							/>
						)}
					</Field>
					<Field name="ownerFirstName">
						{(fieldProps: FieldProps<FormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t('owner-first-name')}
								containerClassName=""
							/>
						)}
					</Field>
					<Field name="ownerLastName">
						{(fieldProps: FieldProps<FormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={`${t('owner-last-name')}*`}
								containerClassName=""
							/>
						)}
					</Field>
					<Field name="ownerEmail">
						{(fieldProps: FieldProps<FormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t('owner-email')}
								containerClassName=""
							/>
						)}
					</Field>
					<Field name="ownerPhone">
						{(fieldProps: FieldProps<FormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t('owner-phone')}
								containerClassName=""
								render={({ field }: FieldProps<string, FormValues>) => {
									const country = useSelector((state: ApplicationState) => state.clinic.item)?.country;

									return <PhoneInput {...field} phoneCode={country?.phoneCode} />;
								}}
							/>
						)}
					</Field>
				</>
			)}

			viewContent={(
				<Field name="">
					{({ form }: FieldProps<FormValues>) => getCardFields(getOwnerInfoFields(form.values, t))}
				</Field>
			)}
		/>
	);
};
