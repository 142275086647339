import * as React from 'react';

import { request } from '@common/react/components/Api';
import { member } from '@common/react/utils/decorators';
import { WithId } from '@common/typescript/objects/WithId';
import { FileInterface, FileType } from '@common/typescript/objects/FileInterface';
import { File } from '@common/react/components/Forms/Files/File';
import { ImageComponent } from '@common/react/components/UI/Image/Image';

import { deleteConfirmation } from '@app/components/UI/Modal/DeleteConfirmation';
import { alertMessage, MessageType } from '@app/utilities/alert';
import avatar from '@app/images/avatar.jpg';

interface AvatarProps<T> {
	type: string;
	object: T;
	onUpdate: (result: OnAvatarUploadResult) => void;
	buttonCaption?: string;
	infoMessage?: string;
	fileType?: FileType;
	property?: string;
	propertyOrigin?: string;
	confirmDelete?: boolean;
	fallback?: string;
}

interface AvatarState {
	isLoading: boolean;
}

export interface OnAvatarUploadResult {
	avatar: string;
	originalAvatar: string;
}

interface ThumbnailProps {
	onConfirmDelete: () => void;
	removeFileUpload: () => void;
	confirmDelete?: boolean;
	path: string;
	fallback?: string;
}

const Thumbnail: React.FC<ThumbnailProps> = (props: ThumbnailProps) => {
	const {
		onConfirmDelete, removeFileUpload, confirmDelete = false, path, fallback = avatar,
	} = props;
	const onDelete = React.useCallback(
		() =>
			(confirmDelete ? onConfirmDelete() : removeFileUpload()),
		[confirmDelete, onConfirmDelete, removeFileUpload],
	);

	return (
		<div className="thumbnail avatar-component__thumb">
			<ImageComponent src={path} fallback={fallback} height={150} width={150} />
			<i className="avatar-component__remove fa fa-times" onClick={onDelete} />
		</div>
	);
};

export class Avatar<T extends WithId> extends React.Component<AvatarProps<T>, AvatarState> {
	@member
	onConfirmDelete(): void {
		deleteConfirmation({
			title: 'avatar',
			callback: () => this.removeFileUpload(),
		});
	}

	@member
	onUpdate(result: Partial<FileInterface>): void {
		if (result.src) {
			this.props.onUpdate({ avatar: result.thumb ?? result.src, originalAvatar: result.src });
		}
	}

	@member
	onDelete(): void {
		const { object, onUpdate } = this.props;

		if (object.id < 0) {
			onUpdate({ avatar: '', originalAvatar: '' });
		} else {
			this.removeFileUpload();
		}
	}

	@member
	removeFileUpload(): Promise<void> {
		const {
			propertyOrigin = 'originalAvatar', type, object, onUpdate,
		} = this.props;

		return request('uploadedFile', {
			objectType: type,
			objectId: object.id,
			src: object[propertyOrigin],
			id: -1,
			deleted: true,
		}).then(() => onUpdate({ avatar: '', originalAvatar: '' }))
			.catch((err) => alertMessage(MessageType.error, err));
	}

	render(): JSX.Element {
		const {
			object, buttonCaption, type, infoMessage, fileType = FileType.Avatar, fallback, property = 'avatar', confirmDelete = false,
		} = this.props;
		const path = object[property];
		const contents = path
			? (
				<Thumbnail
					onConfirmDelete={this.onConfirmDelete}
					removeFileUpload={this.onDelete}
					confirmDelete={confirmDelete}
					path={path}
					fallback={fallback}
				/>
			)
			: (
				<File
					buttonCaption={buttonCaption}
					infoMessage={infoMessage}
					fileType={fileType}
					objectId={object.id}
					type={type}
					onUpdate={this.onUpdate}
				/>
			);

		return <div className="avatar-component">{contents}</div>;
	}
}
