import React from 'react';

import Switch from 'antd/lib/switch';

interface RowControlProps {
	caption: React.ReactNode;
	value: boolean;
	onChange: (value: boolean) => void;
}

export const RowControl: React.FC<RowControlProps> = (props: RowControlProps) => (
	<div className="form-row clearfix">
		<span className="pull-left">{props.caption}:</span>
		<Switch
			checked={props.value}
			className="pull-right icon-margin"
			onChange={props.onChange}
		/>
	</div>
);
