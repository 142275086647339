import React from 'react';

import Icon from 'antd/lib/icon';
import Input, { InputProps } from 'antd/lib/input';
import { InputState } from 'antd/lib/input/Input';

type PasswordFieldProps = InputProps & InputState;

export const PasswordField: React.FC<PasswordFieldProps> = (props: PasswordFieldProps) => {
	const [visible, setVisible] = React.useState<boolean>(false);

	return (
		<Input
			{...props}
			type={visible ? 'text' : 'password'}
			suffix={!visible
				? <Icon type="eye" onClick={() => setVisible(true)} />
				: <Icon type="eye-invisible" onClick={() => setVisible(false)} />}
		/>
	);
};
