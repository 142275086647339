import * as React from 'react';
import { Field, FieldProps } from 'formik';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { CardSize, Card } from '@app/components/UI/Cards/Card';
import { FormValues } from '@app/components/Pages/ClinicPetEditor/types';
import { BaseSectionProps } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/ViewContent';

export const NotesDetails: React.FC<Partial<BaseSectionProps>> = (props: Partial<BaseSectionProps>) => {
	const placeholder = `Please, provide any important additional information.
For example, the owner wants the dog toy to go with the pet but they want it returned...`;

	return (
		<Card
			name="Notes"
			size={CardSize.Medium}
			mode={props.mode}
			fullWidth

			editContent={(
				<Field name="specialInstructions">
					{(fieldProps: FieldProps<FormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							title=""
							containerClassName=""
							render={({ field }) => (
								<textarea
									rows={11}
									className="form-control"
									id={field.name}
									placeholder={placeholder}
									{...field}
								/>
							)}
						/>
					)}
				</Field>
			)}
			viewContent={(
				<Field name="specialInstructions">
					{({ field, form }: FieldProps<FormValues>) => (
						<p style={{ whiteSpace: 'pre-wrap' }}>
							{field.value || `No notes for ${form.values.name} ${form.values.ownerLastName}`}
						</p>
					)}
				</Field>
			)}
		/>
	);
};
