import React from 'react';
import Tooltip from 'antd/lib/tooltip';
import Button from 'antd/lib/button';

interface AdaptiveAddButtonProps {
	itemName: string;
	type?: 'link' | 'default' | 'ghost' | 'primary' | 'dashed' | 'danger';
	className?: string;
	onClick: () => void;
	withIcon?: boolean;
	disabled?: boolean;
}

export const AdaptiveAddButton: React.FC<AdaptiveAddButtonProps> = ({
	itemName,
	onClick,
	className = 'btn btn-primary',
	type = 'primary',
	withIcon,
	disabled,
}: AdaptiveAddButtonProps) => {
	const buttonAddName = `Add ${itemName}`;

	return (
		<>
			<span className="btn--add-with-icon">
				<Tooltip title={buttonAddName}>
					<Button
						type={type}
						icon="plus"
						size="default"
						onClick={onClick}
						className={className}
						disabled={disabled}
					/>
					<span className="visually-hidden">{buttonAddName}</span>
				</Tooltip>
			</span>
			<span className="btn--add-with-word">
				<Button
					type={type}
					icon={withIcon ? 'plus' : undefined}
					onClick={onClick}
					className={className}
					disabled={disabled}
				>
					<span>{buttonAddName}</span>
				</Button>
			</span>
		</>
	);
};
