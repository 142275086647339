import React from 'react';
import { useTranslation } from 'react-i18next';

import { Field, FieldProps } from 'formik';

import Input from 'antd/lib/input';
import Select from 'antd/lib/select';

import { List } from '@common/typescript/objects/List';
import { Nullable } from '@common/typescript/objects/Nullable';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { FormValues } from '@app/components/Various/PriceEditor/PriceEditor';
import { useRequest } from '@app/hooks/useRequest';
import { DeliveryType, DeliveryTypeName, ServiceType } from '@app/objects/Pet';
import { PriceType } from '@app/objects/Price';
import { useDebounce } from '@app/hooks/useDebounce';
import { Stack, StackDirection } from '@app/components/Layout/Stack';

interface OwnProps {
	serviceType: ServiceType;
	priceType: PriceType;
	clinicId: Nullable<number>;

	isModalOpen: boolean;
}

const deliveryTypeKey = 'delivery-type';

interface RequestParams {
	count: number;
	offset: number;
	filters: {
		priceType: PriceType,
		serviceType: ServiceType,
		availableForClinic: Nullable<number>,
	},
	preselect: Array<number>;
	name: string;
}

export const DeliveryPriceComponent: React.FC<OwnProps> = ({
	serviceType,
	priceType,
	clinicId,

	isModalOpen,
}: OwnProps): JSX.Element => {
	const { t } = useTranslation();
	const translator = React.useCallback((key: string) => t(`filters.${key}`), []);
	const [query, setQuery] = React.useState<string>('');

	const params = React.useMemo(() => ({
		count: 20,
		offset: 0,
		filters: {
			priceType,
			serviceType,
			availableForClinic: clinicId,
		},
		preselect: [],
		name: query,
	}), [priceType, serviceType, clinicId]);

	const data = useRequest<List<DeliveryType>, RequestParams>('availableDeliveryTypeList', undefined, { requestOnMount: false });

	React.useEffect(() => {
		if (!isModalOpen) return;

		data.reload(params);
	}, [isModalOpen, params]);

	const debounce = useDebounce((value: Nullable<string>) => data.reload({ ...params, name: value ?? '' }), 750);

	return (
		<Stack
			direction={StackDirection.Horizontal}
			className="row stack-container"
			gap={0}
		>
			<Field name="value">
				{(fieldProps: FieldProps<FormValues>) => (
					<FormikInput
						fieldProps={fieldProps}
						title="Price*"
						containerClassName="col-sm-6"

						render={(formik: FieldProps) => (
							<Input
								value={formik.field.value}
								onChange={(event) => formik.form.setFieldValue(formik.field.name, event.target.valueAsNumber)}

								type="number"
								min={0}
								inputMode="decimal"
							/>
						)}
					/>
				)}
			</Field>
			<Field name="deliveryType">
				{(fieldProps: FieldProps<FormValues>) => (
					<FormikInput
						fieldProps={fieldProps}
						title="Delivery Type"
						containerClassName="col-sm-6"
						render={({ field, form }) => (
							<Select
								showSearch
								showArrow
								showAction={['focus', 'click']}
								optionFilterProp="children"
								placeholder={translator(deliveryTypeKey)}
								value={field.value === null ? undefined : field.value}
								onChange={(value) => form.setFieldValue(field.name, value)}
								onSearch={(query: string) => debounce(query)}
								onSelect={() => setQuery('')}
								onBlur={() => setQuery('')}
							>
								<Select.Option value={-1} key={0} disabled>
									{translator(deliveryTypeKey)}
								</Select.Option>
								{
									data.item?.list.map((value) => (
										<Select.Option value={value} key={value}>
											{t(`enums.${deliveryTypeKey}.${DeliveryTypeName[DeliveryType[value]]}`)}
										</Select.Option>
									))
								}
							</Select>
						)}
					/>
				)}
			</Field>
		</Stack>
	);
};
