import * as React from 'react';
import { useSelector } from 'react-redux';
import Tabs from 'antd/lib/tabs';

import clsx from 'clsx';

import TabsWithUrl from '@common/react/components/UI/TabsWithUrl/TabsWithUrl';

import { PriceType } from '@app/objects/Price';
import { ServiceType } from '@app/objects/Pet';
import { UserRole } from '@app/objects/User';
import { ApplicationState } from '@app/store';
import { useCrematoryServiceType } from '@app/objects/Crematory';

const { TabPane } = Tabs;

interface OwnProps {
	urlPrefix?: string;
	children: (data: SingleTableOptions) => JSX.Element;
}

export interface SingleTableOptions{
	price: PriceType;
	service: ServiceType;
	title: string;
}

interface SingleTableProps {
	name: string;
	urlPrefix?: string;
	priceType: PriceType;
	children: (data: SingleTableOptions) => JSX.Element;
	fullWidth?: boolean;
}

export const SERVICE_TYPE = {
	Communal: 'Communal',
	SemiPrivate: 'Semiprivate',
	Private: 'Private',
	Storage: 'Storage',
};

export const tableOptions = (
	priceType: PriceType,
	useSemiPrivate: boolean,
	useStorage: boolean,
	useCommunal: boolean,
	usePrivate: boolean,
): Array<SingleTableOptions> => {
	const options: Array<SingleTableOptions> = [];

	if (useCommunal) options.push({ price: priceType, service: ServiceType.Communal, title: SERVICE_TYPE.Communal });
	if (useSemiPrivate) options.push({ price: priceType, service: ServiceType.SemiPrivate, title: SERVICE_TYPE.SemiPrivate });
	if (usePrivate) options.push({ price: priceType, service: ServiceType.Private, title: SERVICE_TYPE.Private });
	if (useStorage) options.push({ price: priceType, service: ServiceType.Storage, title: SERVICE_TYPE.Storage });

	return options;
};

export const SingleTable: React.FC<SingleTableProps> = ({
	urlPrefix, name, priceType, children, fullWidth,
}) => {
	const role = useSelector((state: ApplicationState) => state.login.user?.role);
	const {
		useCommunal, usePrivate, useSemiPrivate, useStorage,
	} = useCrematoryServiceType();
	const crematoryUseSemiPrivate = role === UserRole.Admin ? false : useSemiPrivate;
	const crematoryUseStorage = role === UserRole.Admin ? false : useStorage;
	const options = tableOptions(priceType, crematoryUseSemiPrivate, crematoryUseStorage, useCommunal, usePrivate);

	if (!options.length) return null;

	return (
		<div className={clsx('row-tabs', fullWidth ? 'card__body' : 'col-sm-6')}>
			<div className="site-subheadline" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
				<h2>{name}</h2>
			</div>
			<TabsWithUrl tabsUrlKey={urlPrefix ? `${urlPrefix}-${name.toLowerCase()}` : name.toLowerCase()} defaultKey={options[0]?.title.toLowerCase()}>
				{options.map((option) => (
					<TabPane tab={option?.title} key={option?.title.toLowerCase()}>
						{children(option)}
					</TabPane>
				))}
			</TabsWithUrl>
		</div>
	);
};

export const CrematoryTables: React.FC<OwnProps> = ({ children, urlPrefix }: OwnProps) => {
	const role = useSelector((state: ApplicationState) => state.login.user?.role);

	return (
		<div className={clsx('row prices-tables', role === UserRole.Crematory && 'table--margin')}>
			<SingleTable name="Wholesale" urlPrefix={urlPrefix} priceType={PriceType.Wholesale}>{children}</SingleTable>
			<SingleTable name="Retail" urlPrefix={urlPrefix} priceType={PriceType.Retail}>{children}</SingleTable>
		</div>
	);
};
