import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
	Field,
	FieldProps,
	FieldArrayRenderProps,
	FieldArray,
	useFormikContext,
	useField,
} from 'formik';

import Table from 'antd/lib/table';
import Tag from 'antd/lib/tag';
import Tooltip from 'antd/lib/tooltip';
import Icon from 'antd/lib/icon';
import { ColumnProps } from 'antd/lib/table/interface';

import { Nullable } from '@common/typescript/objects/Nullable';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';
import { MobileCell } from '@common/react/components/Pages/ItemsPage';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';

import { FormValues } from '@app/components/Pages/ClinicPetEditor/types';
import {
	CardSize,
	Card,
	ListComponent,
	ItemsList,
} from '@app/components/UI/Cards/Card';
import { ViewMode } from '@app/components/Utils/Utils';
import { getUrnSegments } from '@app/components/Pages/PetEditor/OldPetEditor/Services';

import {
	PetPrice,
	ServiceType,
} from '@app/objects/Pet';
import { useModal } from '@app/hooks/Editors/useModal';

import { BaseSectionProps } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/ViewContent';
import { Preview } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/Preview';
import { NumberField } from '@app/components/UI/NumberField/NumberField';
import { useCrematory } from '@app/hooks/useCrematory';
import {
	StoreEntriesUrnModal,
	allowAdd,
} from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/StoreEntriesModal/StoreEntriesModal';
import { Stack, StackDirection } from '@app/components/Layout/Stack';
import { PreselectStore, urnsError } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/ProcessSection';
import { getAdditionalPrice } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/utils';
import { DefaultUrnProvider } from '@app/components/Pages/PetEditor/OldPetEditor/DefaultUrnProvider';

import '@app/scss/ui/urn-preview.scss';

export function getUrnColumns(
	translate: (key: string) => string,
	setPreview: (value?: string) => void,
	showPricesToClinic: boolean,
	onPreselect: (value: PreselectStore) => void,
	onOpenModal: () => void,
): Array<ColumnProps<PetPrice>> {
	return TableBuilder.shape<PetPrice>()
		.addColumn({
			title: <b>Urn</b>,
			dataIndex: 'name',
			width: '30%',
			render: (text: string, record) => {
				return (
					<MobileCell caption={translate('table.columns.name')}>
						{record.entry?.name ?? 'Unknown Urn'}
						{!allowAdd(record.entry, record.nodeId) && (
							<Tooltip title="Not all parameters for the urn are selected">
								<Icon type="exclamation-circle" className="color-red icon-margin" />
							</Tooltip>
						)}
					</MobileCell>
				);
			},
		})
		.addColumn({
			title: <b>Value</b>,
			dataIndex: 'nodeId',
			render: (id: number, record: PetPrice) => {
				const names = getUrnSegments(record);

				return (
					<MobileCell caption={translate('table.columns.value')}>
						{names.map((cur: string) => (
							<Tag style={{ margin: '0 10px 5px 0' }} key={cur}>
								{cur}
							</Tag>
						))}
					</MobileCell>
				);
			},
		})
		.addColumn({
			title: '',
			dataIndex: '',
			render: (value: Nullable<number>, item: PetPrice) => {
				const price = item;
				const batchCount: Nullable<number> = price?.batchCount ?? null;
				const batchPrice: Nullable<number> = price?.batchPrice ?? null;
				const withBatch = batchCount !== null && batchPrice !== null;

				const batchDescription = withBatch ? `${batchCount} at $${batchPrice.toFixed(2)}` : null;
				const additionalDescription = `Each additional - $${price?.value.toFixed(2)}`;

				return (
					<MobileCell caption="" fullWidth>
						<div>
							{showPricesToClinic && withBatch && batchCount! > 0
								? <div className="special-service-description">{batchDescription}</div>
								: null}
							{showPricesToClinic && price ? <div className="special-service-description">{additionalDescription}</div> : null}
						</div>
					</MobileCell>
				);
			},
		})
		.addColumn({
			title: <b>{translate('editors.pet-editor.labels.urn-count')}</b>,
			dataIndex: 'count',
			width: '150px',
			render: (value: number, record: PetPrice, id: number) => (
				<Field name={`urns.${id}.count`}>
					{(fieldProps: FieldProps<FormValues>) => {
						const error = fieldProps.form.errors.urns?.[id];
						const touched = fieldProps.form.touched.urns?.[id]?.count;

						return (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName={Boolean(fieldProps.form.submitCount) && error && touched && error.count
									? 'table-cell-error'
									: ''}
								render={({ field, form }: FieldProps<number, FormValues>) => (
									<NumberField {...field} disabled={record.done} onChange={(value: number) => form.setFieldValue(field.name, value)} min={1} />
								)}
							/>
						);
					}}
				</Field>
			),
		})
		.addColumn({
			title: '',
			align: 'right',
			dataIndex: 'image',
			render: (_, record: PetPrice) => (
				<Field name="urns">
					{() => {
						const inventoryItem = record.price?.inventoryItem;
						const image = inventoryItem?.originalAvatar ?? inventoryItem?.avatar;

						if (!image) return null;

						return (
							<button
								type="button"
								className="action-container"
								onClick={() => setPreview(image)}
							>
								<i className="fa fa-eye action_link" />
							</button>
						);
					}}
				</Field>
			),
		})
		.addColumn({
			title: '',
			align: 'right',
			fixed: 'right',
			width: '80px',
			render: (value, item: PetPrice, id: number) => {
				if (item.done) return null;

				return (
					<FieldArray
						name="urns"
						render={(props: FieldArrayRenderProps) => (
							<Stack direction={StackDirection.Horizontal}>
								<button
									type="button"
									className="action-container"
									onClick={() => {
										onPreselect({
											storeId: item.entry?.id ?? -1,
											clientId: item.clientId,
											valuesId: [item.nodeId ?? -1],
											count: item.count,
										});
										onOpenModal();
									}}
								>
									<i className="fa fa-pencil action_link" />
								</button>
								<button
									type="button"
									className="action-container"
									onClick={() => {
										if (item.id > 0) {
											props.replace(id, { ...item, removed: true });
											// Move item to the end of the list (so that order of existing items
											// Confirms to order of visible items
											props.move(id, props.form.values.urns.length - 1);
										} else {
											props.remove(id);
										}
									}}
								>
									<i className="fa fa-trash action_danger" />
								</button>
							</Stack>
						)}
					/>
				);
			},
		})
		.build();
}

export function getUrns(urns: Array<PetPrice>): Array<PetPrice> {
	return (urns || []).filter((q: PetPrice) => !q.removed);
}

export function getUrnsList(pet: FormValues): Array<ItemsList> {
	return getUrns(pet.urns)
		.filter((i) => i.node)
		.map((urn: PetPrice) => ({
			id: urn.id,
			title: urn.node?.fullName ?? 'Unknown Urn',
			data: urn.count,
		}));
}

export const UrnList: React.FC<BaseSectionProps> = ({ mode, crematoryId }: BaseSectionProps) => {
	const modal = useModal(false);
	const { t } = useTranslation();
	const [, meta] = useField<Array<PetPrice>>('urns');
	const crematory = useCrematory();
	const showPricesToClinic = crematory?.showPricesToClinic ?? false;
	const { values, setFieldValue } = useFormikContext<FormValues>();

	const [preview, setPreview] = React.useState<string | undefined>(undefined);
	const [preselect, setPreselect] = React.useState<PreselectStore | undefined>(undefined);

	React.useEffect(() => {
		if (preselect && !modal.state) setPreselect(undefined);
	}, [modal.state, preselect]);

	return (
		<>
			<DefaultUrnProvider />
			<FieldArray
				name="urns"
				render={(arrayHelper: FieldArrayRenderProps) => (
					<Card
						name="Urns"
						size={CardSize.Medium}
						mode={mode}
						fullWidth
						withBtn={mode === ViewMode.edit}
						onClick={modal.open}
						disabled={crematoryId <= 0}

						editContent={(
							<>
								<Table
									columns={getUrnColumns(t, setPreview, showPricesToClinic, setPreselect, modal.open)}
									dataSource={getUrns(arrayHelper.form.values.urns)}
									size="small"
									rowKey="clientId"
									pagination={false}
									className="table-mobile"
									loading={arrayHelper.form.values.loadingUrns}
									title={urnsError(meta.error)}
								/>
								{showPricesToClinic && (
									<div className="additional-amount">
										<span>
											Additional amount: ${getAdditionalPrice(getUrns(arrayHelper.form.values.urns)).toFixed(2)}
										</span>
									</div>
								)}
							</>
						)}

						viewContent={(
							<ListComponent
								list={getUrnsList(arrayHelper.form.values)}
								placeholder="There are no urns"
							/>
						)}
					/>
				)}
			/>
			<StoreEntriesUrnModal
				modalControl={modal}
				preselect={preselect}
				onChange={(items: Array<PetPrice>) => setFieldValue('urns', items)}
				serviceType={values.serviceType ?? ServiceType.Private}
			/>
			<Preview image={preview} onClose={() => setPreview(undefined)} />
		</>
	);
};
