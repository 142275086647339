export interface Named {
	firstName: string;
	lastName: string;
}

interface NamedWithEmail extends Named{
	email: string;
}

export const getUserName = (item: Named) =>  item ? `${item.lastName || ''} ${item.firstName || ''}`.trim() : '';

export const getUserNameReverseOrder = (item: Named) => item ? `${item.firstName || ''} ${item.lastName || ''}`.trim() : '';

export const getUserNameWithEmail = (item?: NamedWithEmail) => item ? `${item.lastName || ''} ${item.firstName || ''} (${item.email})` : '';

export const imageSize = (s, size) => {
	return s ?  s.replace(/.(\w+$)/, `${size}.$1`) : '';
};

export const sortByStringField = (field: string) => (a, b) => {
	if (a[field] < b[field]) {
		return -1;
	}

	if (a[field] > b[field]) {
		return 1;
	}

	return 0;
};

export const generateGUID = function (): string {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		const r = Math.random() * 16 | 0,
			v = c === 'x' ? r : (r & 0x3 | 0x8);

		return v.toString(16);
	});
};

/**
 * Converts query-string to Record<string, string> containing all non-empty values
 * @param {string} query - query string to parse
 * @param {Array<string>} keys - an array of expected keys for parsing
 * @returns {Record<string, string>} a key-value map
 */
export function getQueryParams(query: string, keys: Array<string>): Record<string, string> {
	const params = new URLSearchParams(query);
	const result: Record<string, string> = {};

	keys.forEach((key: string) => {
		const value = params.get(key);

		if (value) {
			result[key] = value;
		}
	});

	return result;
}

export const asInteger = function (source: string | null, defaultValue: number = 1) {
	return source && parseInt(source) || defaultValue;
};
