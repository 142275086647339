import React from 'react';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import { useRequest } from '@app/hooks/useRequest';
import { readFile } from '@app/components/UI/Buttons/ImportButton/services';

interface ImportButtonProps {
	endpoint: string;
	message: string;
	description: string;
	onComplete: () => void;
}

export const ImportButton = <
	ImportDataMessage extends object,
>(props: ImportButtonProps) => {
	const ref = React.useRef<HTMLInputElement>(null);
	const [loading, setLoading] = React.useState<boolean>(() => false);
	const request = useRequest<never, ImportDataMessage>(props.endpoint, undefined, { requestOnMount: false });

	return (
		<>
			<input
				ref={ref}
				type="file"
				style={{ display: 'none' }}
				accept=".json"
				value=""
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					if (!event.currentTarget.files?.length) return;

					const file = event.currentTarget.files[0];
					setLoading(true);
					readFile(file)
						.then((data) => request.reload(data as ImportDataMessage | undefined))
						.then(() => {
							notification.success({
								message: props.message,
								description: props.description,
							});
							props.onComplete();
						})
						.catch((error: Error) => {
							notification.error({
								message: props.message,
								description: error.message,
							});
						})
						.finally(() => setLoading(false));
				}}
			/>
			<Button
				type="primary"
				className="btn btn-primary btn-outline pull-right"
				onClick={() => ref.current?.click()}
				loading={loading}
			>
				<span>Import</span>
			</Button>
		</>
	);
};
