import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'antd/lib/select';

interface Option {
	text: string;
	value: number;
}

interface OwnProps {
	onChange: (id: number | undefined) => void;
	value: number | undefined;
	withFieldName?: boolean;
	options: Array<Option>;
	className?: string;
	onDeselect?: (fieldName: string, type: string, id?: number) => void;
	selectClassName?: (value: Array<number> | number | undefined, length: number) => string;
}

const { Option } = Select;

export const PetSourceSelect = (props: OwnProps): JSX.Element => {
	const {
		onChange, value, withFieldName, className, onDeselect, selectClassName, options,
	} = props;
	const { t } = useTranslation('translation', { keyPrefix: 'filters' });
	const [query, setQuery] = React.useState<string>('');

	return (
		<div className={className}>
			{withFieldName && <span className="field-name">{t('pet-source')}</span>}
			<Select
				className={selectClassName ? selectClassName(value, query.length) : ''}
				showSearch
				showArrow
				showAction={['focus', 'click']}
				allowClear
				placeholder={t('pet-source')}
				optionFilterProp="children"
				onSearch={(query: string) => setQuery(query)}
				onChange={(id: number) => onChange(id === -1 ? undefined : id)}
				onSelect={() => setQuery('')}
				onBlur={() => setQuery('')}
				value={value === -1 ? undefined : value}
				onDeselect={(value: number) => onDeselect && onDeselect('petSource', 'id', value)}
			>
				{options.map((item) =>
					<Option key={item.value} value={Number(item.value)}>{item.text}</Option>)}
			</Select>
		</div>
	);
};
