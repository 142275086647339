import * as React from 'react';

import Table from 'antd/lib/table';
import { v4 } from 'uuid';
import {
	Field, FieldProps, Form, Formik,
} from 'formik';
import Input from 'antd/lib/input';

import { List } from '@common/typescript/objects/List';
import { MobileCell } from '@common/react/components/Pages/ItemsPage';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { useTableRemoteFilter } from '@common/react/hooks/RemoteData/useTableRemoteFilter';
import { useTableRemoteSorter } from '@common/react/hooks/RemoteData/useTableRemoteSorter';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';
import { Column } from '@common/react/smart components/Table/TableRemoteSorter';

import { ModalWrapper } from '@app/components/UI/Modal/Modal';
import { textSearch } from '@app/components/Filters/TextSearch';
import { Price, PriceType } from '@app/objects/Price';
import { PetPrice, ServiceType } from '@app/objects/Pet';
import { SpecialServiceType } from '@app/objects/SpecialService';
import {
	getPrice,
} from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/SpecialServiceDialog';

import { useRequest } from '@app/hooks/useRequest';
import { useCrematory } from '@app/hooks/useCrematory';

import '@app/scss/ui/modals.scss';

interface PageProps {
	petId: number;
	serviceType: ServiceType;
	priceType: PriceType;

	value: Array<PetPrice>;
	onChange: (value: Array<PetPrice>) => void;

	state: boolean;
	close: () => void;
}

const getDialogColumns = (showPricesToClinic: boolean = false) => TableBuilder.shape<Price>()
	.addColumn({
		title: 'Name',
		dataIndex: 'name',
		render: (_, record: Price) => <MobileCell caption="Name" fullWidth>{record?.specialService?.name}</MobileCell>,
		...textSearch('by name'),
		sorter: true,
	})
	.addColumn({
		title: 'Description',
		dataIndex: 'description',
		render: (_, record: Price) => <MobileCell caption="Description" fullWidth>{record?.specialService?.description}</MobileCell>,
	})
	.addColumn({
		title: 'Note',
		dataIndex: 'specialService.defaultNote',
		render: (_, record: Price, index) => {
			if (!record.specialService?.hasNote) return null;

			return (
				<Field name={`${index}.specialService.defaultNote`}>
					{({ field, form, meta }: FieldProps<string, Price>) => (
						<FormikInput
							fieldProps={{ field, form, meta }}
							containerClassName=""
							render={({ field, form }) => (
								<Input
									value={field.value}
									id={field.name}
									onClick={(e) => e.stopPropagation()}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										form.setFieldValue(field.name, e.currentTarget.value);
									}}
								/>
							)}
						/>
					)}
				</Field>
			);
		},
	})
	.addColumn({
		title: '',
		dataIndex: 'value',
		align: 'right',
		render: (value: number) => <MobileCell caption="">{getPrice(value)}</MobileCell>,
	}, showPricesToClinic)
	.build();

interface ServiceParams {
	priceType: PriceType;
	serviceType: ServiceType;

	withSpecialService: boolean;
	isSpecialService: boolean;
	withMyClinic: boolean;

	filters: {
		excluding: Array<number>;
		specialServiceType: Array<SpecialServiceType>;
	},

	column: Array<Column>;
}

const pageSize = 20;
export const SpecialServiceDialog: React.FC<PageProps> = (props: PageProps) => {
	const filter = useTableRemoteFilter<Price>();
	const sorter = useTableRemoteSorter<Price>();
	const crematory = useCrematory();
	const showPricesToClinic = crematory?.showPricesToClinic;

	const [currentPage, setCurrentPage] = React.useState<number>(0);
	const [services, setServices] = React.useState<Array<Price>>(() => []);
	const [selectedItems, setSelectedItems] = React.useState<Array<PetPrice>>(() => (props.value || []));

	const availableServices = useRequest<List<Price>, ServiceParams>('availablePriceList', undefined, { requestOnMount: false });

	React.useEffect(() => {
		if (props.state) {
			setSelectedItems(props.value);
		}
	}, [props.state]);

	const reqParams = React.useMemo(() => ({
		priceType: props.priceType,
		serviceType: props.serviceType,

		withSpecialService: true,
		isSpecialService: true,
		withMyClinic: true,

		filters: {
			...filter.filters,
			excluding: props.value?.map((item) => item.priceId),
			specialServiceType: [SpecialServiceType.Service, SpecialServiceType.Kit],
		},
		...sorter.sorter,
		count: pageSize,
		offset: pageSize * currentPage,
	}), [props.priceType, props.serviceType, filter, sorter, props.value, currentPage]);

	const selectedIds = React.useMemo<Array<number>>(() =>
		selectedItems.map((item: PetPrice) => item.priceId), [selectedItems]);

	const onSelectChange = (record: Price, selected: boolean) => {
		if (selected) {
			const selectedItem: PetPrice = {
				id: -1,
				clientId: v4(),
				name: record.specialService?.name ?? 'Special Service',

				priceId: record.id,
				price: record,

				petId: props.petId,
				pet: null,

				count: record.batchCount || 1,
				completedCount: 0,
				done: false,

				value: record.batchCount > 0 ? record.batchPrice : record.value,
				extra: record.batchCount > 0 ? 0 : record.value,

				editor: null,
				editorId: null,

				batchPrice: record.batchPrice,
				batchCount: record.batchCount,

				pickupService: null,
				pickupServiceId: null,

				node: null,
				nodeId: null,

				note: record.specialService?.defaultNote ?? '',
			};
			setSelectedItems((s: Array<PetPrice>) => ([...s, selectedItem]));
		} else {
			const filteredItems = selectedItems.filter((item: PetPrice) => item.priceId !== record.id);
			setSelectedItems(filteredItems);
		}
	};

	React.useEffect(() => {
		if (!props.state) return;

		availableServices.reload(reqParams);
	}, [reqParams, props.state]);

	React.useEffect(() => {
		if (props.state && availableServices.item?.list?.length) setServices([...availableServices.item.list]);
	}, [availableServices.item?.list, props.state]);

	const rowSelection = {
		selectedRowKeys: selectedIds,
		onSelect: onSelectChange,
	};

	return (
		<Formik
			initialValues={services}
			onSubmit={(values: Array<Price>) => {
				const items = selectedItems.map((i) => {
					const value = values.find((v) => v.id === i.priceId);
					if (value) {
						return { ...i, note: value.specialService?.defaultNote ?? '' };
					}

					return i;
				});
				props.onChange(items);
				props.close();
			}}
			enableReinitialize
		>
			{(formikBag) => (
				<Form>
					<ModalWrapper
						title="Add special service"
						isOpened={props.state}
						onOk={formikBag.submitForm}
						onCancel={() => {
							setSelectedItems([]);
							props.close();
						}}
						modalClassName="services-modal"
						width="60%"
					>
						<Table
							columns={getDialogColumns(showPricesToClinic)}
							dataSource={formikBag.values}
							pagination={{
								total: availableServices.item?.count,
								pageSize: 20,
								hideOnSinglePage: true,
								current: currentPage + 1,
								onChange: (page) => {
									const value = (page ?? 1) - 1;
									setCurrentPage(value);
								},
							}}
							loading={availableServices.loading}
							onChange={(pagination, filters, sorterResult) => {
								if (!Object.keys(sorterResult).length || pagination.current !== currentPage + 1) return;

								sorter.setSorter(sorterResult);
								filter.onFilter(filters);
							}}
							childrenColumnName="child"
							rowKey="id"
							className="table-mobile pets-table"
							rowSelection={rowSelection}
							onRow={(record) => ({
								onClick: (event) => {
									event.preventDefault();
									const isSelect = selectedIds.includes(record.id);
									onSelectChange(record, !isSelect);
								},
							})}
						/>
					</ModalWrapper>
				</Form>
			)}
		</Formik>
	);
};
